import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Item, AddToCartAction, Catitem, PriceDistribution, PriceD, Discount, Search2 } from '../../config/function'
import likeoff from '../../images/likeoff.svg'
import rate from '../../images/rate.svg'
import { QuickView } from '../alert/alert'
import { Search, Mformatter, Quotient } from '../../config/function';


export const Search2Query = ({ query }) => {
    const { state } = useLocation();

    console.log(Search(state.query))

    var account = JSON.parse(localStorage.getItem('account'))
    const [qty, setQty] = useState(1);
    const [item, setItem] = useState([])
    const [qv, setQv] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        setItem(Search2(state.query.brand, state.query.category))
    }, [state.query]);

    return (
        <div className="w-full flex flex-col mx-auto px-4 mt-10">
            <div className="flex flex-col  p-4">
                <div className="flex w-full lg:w-4/6 mx-auto">
                    <h1 className='text-xl lg:text-2xl font-bold'>Query results for ({state.query.brand.FirstUppercase()} {state.query.category.FirstUppercase()}) {item.length} devices</h1>
                    <div className="flex gap-3">
                        <div className="rounded-full p-2"> </div>
                        <div className="rounded-full p-2"> </div>
                    </div>
                </div>
                <div className="w-full flex-col md:grid md:grid-cols-2  lg:grid lg:grid-cols-4 items-center gap-4 mt-10 mx-auto lg:w-4/6" >
                    {item.length < 1 && <h1>No Search Query results</h1>}
                    {item.length > 0 && item.map((value, index, array) => (
                        <div className="flex flex-col p-4 gap-2 rounded-lg w-full bg-slate-50" key={index}>
                            <div className="flex justify-between w-full">
                                <p className="p-2 rounded-lg bg-white text-sm">{value.brand}</p>
                                <img className='h-10 p-2 rounded-full bg-white' src={likeoff} alt="heart" />
                            </div>
                            <div className="flex p-2 justify-center relative">
                                <img className="object-contain h-48 w-44" src={value.img} alt={value.displayname} />
                                {value.discount > 0 ? <div className="flex flex-col justify-center items-center rounded-full bg-black px-4 py-2 absolute right-2 -top-5">
                                 {parseInt(Discount((value.price / Quotient), PriceD(value)).replace('%', '')) > 0 && ( <h1 className='text-white text-xs font-bold'>was {Mformatter(value.price / Quotient)}</h1>)}
                                </div> : null}
                                {parseInt(Discount((value.price / Quotient), PriceD(value)).replace('%', '')) > 0 && (<p className="font-bold text-black rounded-full bg-gray-200 w-max h-max p-2 mt-4">-{Discount((value.price / Quotient), PriceD(value))}</p>)}

                            </div>
                            <div className="flex justify-center gap-4 my-2">
                                <button className="rounded-2xl py-2 px-4 text-xs hover:shadow-md cursor:hover hover:translate-y-1 delay-100 bg-black text-white"
                                    onClick={() => {
                                        AddToCartAction(value, account, qty, setQty)
                                    }}
                                >Add to cart</button>
                                <button className="rounded-2xl py-2 px-4 text-xs hover:shadow-md cursor:hover hover:translate-y-1 delay-100 border-1 border-black"
                                    onClick={() => navigate('/fullspecification', { state: { obj: value } })}
                                >Full Specification</button>


                            </div>
                            {/* <div className="flex gap-2">
                                <div className="rounded-full h-4 w-4 p-1 bg-blue-300" ></div>
                                <div className="rounded-full h-4 w-4 p-1 bg-red-300" ></div>
                                <div className="rounded-full h-4 w-4 p-1 bg-green-300" ></div>
                                <div className="rounded-full h-4 w-4 p-1 bg-orange-300" ></div>
                            </div> */}
                            <div className="bg-white rounded-lg p-2">
                                <div className="flex flex-col gap-2 mt-4">
                                    <h1 className="text-md font-bold truncate ">{value.displayname}</h1>
                                    <h1 className="text-sm font-normal truncate">{value.short}</h1>
                                </div>
                                <div className="flex justify-between mt-5">
                                    <button className="border-2 border-green-600 rounded-lg bg-white px-2 text-green text-lg font-bold text-green-600 hover:bg-green-200">{Mformatter(PriceD(value)*qty)}</button>
                                    <div className="flex gap-2 items-center">
                                        <img src={rate} alt="rate" className="h-4" />
                                        <p className="text-sm">{(value.rate)} ({value.review} reviews)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}


