import { useLayoutEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import HomePage from './pages/home_page';
import 'tw-elements';
import './App.css'
import CartPage from './pages/cart_page';
import AccountPage from './pages/account_page';
import SigninPage from './pages/signin_page';
import SignupPage from './pages/signup_page';
import PaymentPage from './pages/payment_page';
import Page404 from './pages/404';
import LaptopPage from './pages/laptop_page';
import PhoneTabPage from './pages/phonestab_page';
import DesktopPage from './pages/desktop_page';
import WatchesPage from './pages/watches_page';
import CheckoutPage from './pages/checkout_page';
import SearchPage from './pages/search_page';
import Search2Page from './pages/search_2_page';
import SpecsPage from './pages/specs_page';
import PrivacyPolicy from './pages/privacypolicy';
import Termsandconditions from './pages/termsandcondition';


const App = () => {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  }
  return (
    <div className="flex justify-center">
      <BrowserRouter >
        <Wrapper>
          <div className="flex w-full justify-center">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/cartitems" element={<CartPage />} />
              <Route path="/myaccountpage" element={<AccountPage />} />
              <Route path="/signintoaccount" element={<SigninPage />} />
              <Route path="/signupforaaccount" element={<SignupPage />} />
              <Route path="/mypaymentpage" element={<PaymentPage />} />
              <Route path="/laptopcomputers" element={<LaptopPage />} />
              <Route path="/phones&amp;Tablets" element={<PhoneTabPage />} />
              <Route path="/desktopcomputerss" element={<DesktopPage />} />
              <Route path="/smartatches" element={<WatchesPage />} />
              <Route path="/checkoutpage" element={<CheckoutPage />} />
              <Route path="/searchitempage" element={<SearchPage />} />
              <Route path="/searchnameandcatitempage" element={<Search2Page />} />
              <Route path="/fullspecification" element={<SpecsPage />} />
              <Route path="/privacytermsandconditions" element={<PrivacyPolicy />} />
              <Route path="/licensetermsandconditionsagreement" element={<Termsandconditions />} />
              <Route path="*" element={<Page404 />} />
            </Routes>
          </div>
        </Wrapper>
      </BrowserRouter>
    </div>
  );
};

export default App;
