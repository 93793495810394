import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code';
import { useLocation, useNavigate } from 'react-router-dom'
import { CheckPaymentAPI, } from '../../config/function'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { UpdateADoc } from '../../config/db';
import { AlertDialog } from '../alert/alert';

export const Payments = () => {
    const { state } = useLocation();
    const [pay, setPay] = useState([])
    const [price, setPrice] = useState("bitcoin")
    const [address, setAddress] = useState("");
    const [ispaied, setisPaied] = useState(false);
    const navigate = useNavigate();
    var account = JSON.parse(localStorage.getItem('account'))


    /* 'GMVAWRAY' */
    useEffect(() => {

        CheckPaymentAPI(state.code)
            .then((v) => v.json())
            .then((v) => {
                setPay([v.data])
                console.log(v.data)
                setAddress(v.data.addresses.bitcoin)
                let q = setInterval(() => {
                    console.log('data 2: ', v.data.payments)
                    var balance = (parseFloat(account.balance) + parseFloat(v.data.payments[0].value.local.amount))
                    if (v.data.payments.length > 0) {
                        clearInterval(q)
                        setisPaied(true)
                        UpdateADoc(account.email, 'accounts', { balance })
                            .then((value) => {
                                AlertDialog({
                                    title: "Deposite Successful",
                                    msg: "You have succesfully topped up your account with $" + v.data.payments[0].value.local.amount,
                                    ybtn: "Okey",
                                    conf: true,
                                    action: () => navigate("/myaccountpage", { replace: true })
                                })
                            })
                    }
                }, 60000)
            })

    }, [state.code]);



    return (
        <div className='flex flex-col w-full items-center mt-5 mb-8 px-4'>
            {pay.length < 1 && (<div className="flex justify-center items center">
                <p className="font-bold">Initializing Payment, Please wait</p>
            </div>)}

            {pay.length > 0 && (<div className='flex flex-col justify-center w-full lg:w-2/5 items-center rounded-xl border-1 border-gray-600 p-4'>
                <h1 className="font-extrabold xs:text-md text-xl md:text-3xl mt-4">Picolocal BALANCE TOPUP</h1>
                <h1 className="text-2xl mt-5">Order#: {pay[0].code}</h1>
                <h1 className="font-bold text-md mt-5 mx-4 text-center">{pay[0].description}. <span className="text-red-500">Don't close this window untill the payment is completed.</span></h1>
                <div className="flex p-4 my-4 border-4 border-gray-500 rounded-lg">
                    <QRCode
                        size={150}
                        fgColor=""
                        value={address}
                        viewBox={`0 0 150 150`}
                    />
                </div>
                <p className="my-2">Paywith: <span className="font-bold text-lg text-red-600 ">{price.toUpperCase()}</span></p>
                <div className="flex gap-4 items-center">
                    <h1 className="font-bold text-lg my-2">{pay[0].pricing['local'].currency} {pay[0].pricing['local'].amount}</h1>
                    <h1 className="font-bold text-lg my-2 text-red-600 ">{parseFloat(pay[0].pricing[price].amount).toFixed(6)} {pay[0].pricing[price].currency}</h1>
                </div>

                <select className='px-4 py-1 border-2 border-gray-500 rounded-lg' onChange={(e) => {
                    setAddress(e.target.value.split("*")[0])
                    setPrice(e.target.value.split("*")[1])
                }
                }>

                    <option value={pay[0].addresses.bitcoin + '*bitcoin'}>Select Address</option>
                    <option value={pay[0].addresses.bitcoin + '*bitcoin'}>Bitcoin - BTC</option>
                    <option value={pay[0].addresses.ethereum + '*ethereum'}>Ethereum - ETH</option>
                    <option value={pay[0].addresses.shibainu + '*shibainu'}>Shibainu - SHIB</option>
                    <option value={pay[0].addresses.tether + '*tether'}>Tether - USDT</option>
                    <option value={pay[0].addresses.bitcoincash + '*bitcoincash'}>Bitcoincash - BCH</option>
                    <option value={pay[0].addresses.dogecoin + '*dogecoin'}>Dogecoin - DOGE</option>
                    <option value={pay[0].addresses.litecoin + '*litecoin'}>Litecoin - LTC</option>
                    <option value={pay[0].addresses.apecoin + '*apecoin'}>Apecoin - APE</option>
                    <option value={pay[0].addresses.dai + '*dai'}>Dai - DAI</option>

                </select>
                <h1 className="text-lg mt-5 text-center">Click to copy address <span className="text-sm font-bold text-red-600 cursor-pointer"
                    onClick={() => { }}
                ><CopyToClipboard text={address}>
                        <button>{address}</button>
                    </CopyToClipboard></span></h1>
                <div className="my-4 flex flex-col justify-center">

                    {!ispaied && (<div className="flex justify-center items-center">
                        <div className='flex flex-col items-center mt-2'>
                            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full mb-3" role="status" />
                            <p className="">Waiting for payment</p>
                        </div>


                    </div>)}
                    {ispaied && (<div className="flex flex-col my-3">
                        <p className="text-md">{pay[0].payments[0].value.local.amount} {pay[0].payments[0].value.local.currency}</p>
                        <p className="text-md"> {pay[0].payments[0].network} {pay[0].payments[0].value.crypto.amount} {pay[0].payments[0].value.crypto.currency}</p>
                        <p className="text-md">Payment: {pay[0].payments[0].status}</p>

                        <button className="bg-black text-white my-1" onClick={() => navigate("/myaccountpage", { replace: true })}>close</button>
                    </div>)}
                </div>
            </div>)
            }
        </div >
    )
}


