import React, { useRef, useState, useLayoutEffect, useEffect } from 'react'
import { UID } from '../../config/configdata';
import { GetADocWhere, SetADoc, GetADoc, DeleteMutipleDocs, UpdateADoc, DeleteADoc } from '../../config/db'
import { Formatter, Mformatter, CheckoutAddress, PriceD, SendEmail } from '../../config/function';
import { where } from 'firebase/firestore';

import { useNavigate } from 'react-router-dom'
import { FullCart } from '../../data/data_items'
import { AlertDialog, Progress, Edit } from '../alert/alert'
import '../../index.css'
import { PaymentDialog } from '../alert/alert'

import emptycart from '../../images/emptycart.png'
import moment from 'moment';
import { FaTrash, FaRegEdit, FaCheckCircle, FaPlusCircle } from 'react-icons/fa'

const CheckoutComp = () => {




    const [billing, setBilling] = useState([])
    const [shipping, setShipping] = useState([])
    const [currentshipping, setCurrentshipping] = useState([])
    const [currentbilling, setCurrentbilling] = useState([])

    const navigate = useNavigate();
    const [cartitems, setCartitems] = useState([])
    const [totalbal, setTotalbal] = useState(0)
    const [cartno, setCartno] = useState(0);
    const [qty, setQty] = useState(1);
    const [prog, setProg] = useState(false);

    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [addresstype, setAddresstype] = useState('Shipping')
    const [aemail, setAemail] = useState("");
    const [aphone, setAphone] = useState("");
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [country, setCountry] = useState('')
    const [city, setCity] = useState('')
    const [zip, setZip] = useState('')
    const [sa, setSa] = useState('')
    const [uuid, setUuid] = useState([])
    const [activate, setActivate] = useState('Add new')


    const emailref = useRef();
    const phoneref = useRef();
    const aemailref = useRef();
    const aphoneref = useRef();
    const nameref = useRef();
    const addressref = useRef();
    const countryref = useRef();
    const cityref = useRef();
    const zipref = useRef();

    const [info, setInfo] = useState({})
    const [acc, setAcc] = useState([]);

    var prices = 0;

    var account = JSON.parse(localStorage.getItem('account'))
    var checkoutitems = []
    var deleteitems = []
    try {


        if (account) {

            GetADocWhere('cart', where('email', '==', account.email))
                .then((value) => {
                    setCartno(value.length)
                    setCartitems(value)
                    value.forEach((value, index, array) => {
                        prices += parseFloat((value.price) * value.qty)
                        checkoutitems.push({ uid: value.uid, qty: value.qty })
                        deleteitems.push(value.id)
                    })
                    setTotalbal(prices)
                }).catch((err) => { console.log(err) })
        }
    } catch (error) {

    }

    var accoun = JSON.parse(localStorage.getItem('account'))

    useLayoutEffect(() => {
        setInfo(account)
        setAcc([account])

    }, [])
    useEffect(() => {
        setEmail(account.email)
        setPhone(Formatter(account.phone))
        GetADocWhere("shipping", where("id", "==", account.email))
            .then((v) => {
                v && setShipping(v)
            })

        GetADocWhere("billing", where("id", "==", account.email))
            .then((v) => {
                v && setBilling(v)
            })

        GetADoc("activeshipping", account.email).then(v => {
            v.data() && setCurrentshipping([v.data()])
        })

        GetADoc("activebilling", account.email).then(v => {
            v.data() && setCurrentbilling([v.data()])
        })
    }, [currentshipping, currentbilling, account.email, account.phone])

    return (
        <div className="w-full flex lg:w-4/6 flex-col mx-auto px-4 my-8">
            <div className="flex flex-col p-4">
                <h1 className='text-2xl lg:text-4xl font-bold mb-5'>Checkout</h1>
                <div className="flex w-full mx-auto">
                    <div className="flex w-full flex-col lg:flex-row gap-20">

                        <div className="flex flex-col w-full lg:w-1/2  gap-8 lg:flex-row justify-between">

                            <div className="flex flex-col w-full">

                                <div className="flex gap-8 items-center my-8">
                                    <button className="w-max rounded-full px-6 py-2 bg-black text-white " onClick={() => PaymentDialog(navigate)}>Topup Balance</button>
                                    <p className="text-lg h-max font-bold">{Mformatter(parseInt(info.balance))}</p>
                                </div>

                                {currentshipping.length > 0 && <div className="mt-5">
                                    <h1 className="font-bold mb-2">Default shipping Address</h1>
                                    <p className="text-sm">Name: <span className="font-bold">{currentshipping[0].name}</span></p>
                                    <p className="text-sm">Email address: <span className="font-bold">{currentshipping[0].email}</span></p>
                                    <p className="text-sm">Address: <span className="font-bold">{currentshipping[0].address}</span></p>
                                    <p className="text-sm">Shipping agent: <span className="font-bold">{currentshipping[0].agent}</span> will deliver the package</p>
                                    <p className="text-sm">Phone: <span className="font-bold">+ {info.code}{Formatter(currentshipping[0].phone)}</span></p>
                                    <p className="text-sm">Country: <span className="font-bold">{currentshipping[0].country}</span></p>
                                    <p className="text-sm">City: <span className="font-bold">{currentshipping[0].city}</span></p>
                                    <p className="text-sm">Zip code: <span className="font-bold">{currentshipping[0].zip}</span></p>
                                </div>}
                                {currentbilling.length > 0 && <div className="mt-5">
                                    <h1 className="font-bold mb-2">Default billing Address</h1>
                                    <p className="text-sm">Name: <span className="font-bold">{currentbilling[0].name}</span></p>
                                    <p className="text-sm">Email address: <span className="font-bold">{currentbilling[0].email}</span></p>
                                    <p className="text-sm">Address: <span className="font-bold">{currentbilling[0].address}</span></p>
                                    <p className="text-sm">Phone: <span className="font-bold">+ {info.code}{Formatter(currentbilling[0].phone)}</span></p>
                                    <p className="text-sm">Country:<span className="font-bold"> {currentbilling[0].country}</span></p>
                                    <p className="text-sm">City: <span className="font-bold">{currentbilling[0].city}</span></p>
                                    <p className="text-sm">Zip code: <span className="font-bold">{currentbilling[0].zip}</span></p>
                                </div>}

                                <div className="flex p-4 items-center justify-between gap-4 border-1 border-gray-400 rounded-lg mt-5">
                                    <svg className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.50998C8.71997 7.69998 10.18 6.22998 12 6.22998C13.81 6.22998 15.28 7.69998 15.28 9.50998C15.27 11.28 13.88 12.72 12.12 12.78Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M18.74 19.3801C16.96 21.0101 14.6 22.0001 12 22.0001C9.40001 22.0001 7.04001 21.0101 5.26001 19.3801C5.36001 18.4401 5.96001 17.5201 7.03001 16.8001C9.77001 14.9801 14.25 14.9801 16.97 16.8001C18.04 17.5201 18.64 18.4401 18.74 19.3801Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>  <div>
                                        <div className="flex">
                                            <p className="text-md md:text-lg">{account.name} | {account.country} | {account.city}</p>
                                            <svg fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                                        </div>
                                        <p className="text-xs">{Formatter(`+${account.code} ${account.phone}`)} | {account.address}</p>
                                    </div>
                                    <button className="bg-gray-100 px-4 py-1 rounded-md h-max" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">Change</button>
                                </div>
                                <div className="collapse" id="collapseExample">
                                    <div className="block p-6 rounded-lg shadow-lg bg-white">
                                        <p className="text-lg mb-2">Email address</p>
                                        <input className="px-4 py-2 mb-4 border-1 focus:shadow-md focus:shadow-bg-300 focus:border-2 border-gray-400 rounded-2xl outline-none w-full" type="email" name="email" id="email" value={email} ref={emailref} onChange={(e) => setEmail(e.target.value)} />

                                        <p className="text-lg mb-2">Phone number</p>
                                        <input className="px-4 py-2 mb-4 border-1 focus:shadow-md focus:shadow-bg-300 focus:border-2 border-gray-400 rounded-2xl outline-none w-full" type="text" name="phone" id="phone" value={phone} ref={phoneref} onChange={(e) => setPhone(e.target.value)} />

                                        <button className="bg-black text-white text-lg px-6 py-2 rounded-full mt-4 disabled">Update contact account</button>
                                    </div>
                                </div>
                                {currentshipping.length < 1 && <p className="my-8 font-bold">Please add a shipping address before you can checkout your order. <span className="text-red-600 cursor-pointer" onClick={() => setAddresstype("Shipping")}>Click here</span> to add a shipping address.</p>}



                                {currentshipping.length > 0 && <div className="flex p-4 items-center justify-between gap-4 border-1 border-gray-400 rounded-lg mt-5">
                                    <svg className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M7.14008 6.75L5.34009 8.55L7.14008 10.35" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M16.8601 6.75L18.6601 8.55L16.8601 10.35" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                    <div>
                                        <div className="flex">
                                            <p className="text-md md:text-lg">SHIPPING ADDRESS THROUGH: <span className='font-extrabold px-2 rounded-lg bg-gray-200 cursor-pointer'>{currentshipping[0].agent}</span></p>
                                            <svg fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                                        </div>
                                        <p className="text-xs">{currentshipping[0].address}</p>
                                    </div>
                                    <button className="bg-gray-100 px-4 py-1 rounded-md h-max" data-bs-toggle="collapse" data-bs-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample" onClick={() => setAddresstype('Shipping')}>Change</button>
                                </div>}
                                {currentshipping.length > 0 && <div className="collapse" id="collapseExample2">
                                    <div className="block p-6 rounded-lg shadow-lg bg-white">
                                        {shipping && shipping.map((value, index, array) => (
                                            <div className={`bg-gray-100 p-2 rounded-lg mb-2 gap-2 cursor-pointer relative ${currentshipping[0].uid === value.uid ? "border-1 border-black" : ""}`} key={index} >

                                                <div className="flex gap-2 mr-1 mb-2">
                                                    <FaPlusCircle data-bs-toggle="tooltip" data-bs-placement="top" title="Set new shipping address" onClick={() => {
                                                        setActivate("Add new")
                                                        setAddresstype("Shipping")
                                                        AlertDialog({
                                                            title: "Add new address",
                                                            msg: "Scroll down to the form below to add a new " + addresstype + " address",
                                                            nbtn: "Close",
                                                            conf: false,

                                                        })
                                                    }} />
                                                    <FaCheckCircle data-bs-toggle="tooltip" title="Set as default shipping address" onClick={() => CheckoutAddress(value)} />
                                                    <FaRegEdit data-bs-toggle="tooltip" title="Edit shipping address" onClick={() => {
                                                        console.log(value.uid)
                                                        setAemail(value.email)
                                                        setAphone(value.phone)
                                                        setName(value.name)
                                                        setAddress(value.address)
                                                        setCountry(value.country)
                                                        setCity(value.city)
                                                        setZip(value.zip)
                                                        setActivate("Modify")
                                                        setUuid([value.id, value.uid])
                                                        AlertDialog({
                                                            title: "Edit address",
                                                            msg: "Scroll down to the form below to update your address",
                                                            nbtn: "Close",
                                                            conf: false,

                                                        })

                                                    }} />
                                                    <FaTrash color="#ff0000" data-bs-toggle="tooltip" title="Delete this address" onClick={()=>{
                                                        setUuid([value.id, value.uid])
                                                        AlertDialog({
                                                            title: "Delete address",
                                                            msg: "Are you sure you want to delete this address?",
                                                            conf: true,
                                                            nbtn: "Close",
                                                            ybtn: "Delete Address",
                                                            action: () => {
                                                                DeleteADoc(uuid[0], `active${addresstype.toLowerCase()}`)
                                                                DeleteADoc(uuid[1], addresstype.toLowerCase())
                                                                    .then((v) => {
                                                                        AlertDialog({
                                                                            title: "Success",
                                                                            msg: "addresses deleted successfully",
                                                                            conf: false,
                                                                            nbtn: "Close"
    
                                                                        })
                                                                        // window.location.reload()
                                                                    })
                                                                    .catch(err => {
                                                                        AlertDialog({
                                                                            title: "Error",
                                                                            msg: err.message.replace("Firebase: ", ""),
                                                                            conf: false,
                                                                            nbtn: "Close"
                                                                        })
                                                                    })
                                                            }
                                                        })
                                                    }}/>

                                                </div>
                                                <p className="text-sm">Name: {value.name}</p>
                                                <p className="text-sm">Email address: {value.email}</p>
                                                <p className="text-sm">Address: {value.address}</p>
                                                <p className="text-sm">Phone: +{info.code}{Formatter(value.phone)}</p>
                                                <p className="text-sm">Country: {value.country}</p>
                                                <p className="text-sm">City: {value.city}</p>
                                                <p className="text-sm">Zip code: {value.zip}</p>

                                                {currentshipping[0].uid === value.uid && <input className='absolute top-2 right-1 form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer' type="checkbox" checked name="chb" id={"ch" + index} />}

                                            </div>
                                        ))}
                                    </div>
                                </div>}

                                {currentbilling.length < 1 && <p className="my-8 font-bold">Please add a billing address before you can checkout your order. <span className="text-red-600 cursor-pointer" onClick={() => setAddresstype("Billing")}>Click here </span> to add billing address.</p>}

                                {currentbilling.length > 0 && <div className="flex p-4 items-center justify-between gap-4 border-1 border-gray-400 rounded-lg mt-5">
                                    <svg className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.92969 15.8792L15.8797 3.9292" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M11.1013 18.2791L12.3013 17.0791" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M13.793 15.5887L16.183 13.1987" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M3.60127 10.239L10.2413 3.599C12.3613 1.479 13.4213 1.469 15.5213 3.569L20.4313 8.479C22.5313 10.579 22.5213 11.639 20.4013 13.759L13.7613 20.399C11.6413 22.519 10.5813 22.529 8.48127 20.429L3.57127 15.519C1.47127 13.419 1.47127 12.369 3.60127 10.239Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M2 21.9985H22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>             <div>
                                        <div className="flex">
                                            <p className="text-md md:text-lg">CUSTOMER BILLING ADDRESS</p>
                                            <svg fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                                        </div>
                                        <p className="text-xs">{currentbilling[0].address}</p>
                                    </div>
                                    <button className="bg-gray-100 px-4 py-1 rounded-md h-max" data-bs-toggle="collapse" data-bs-target="#collapseExample3" aria-expanded="false" aria-controls="collapseExample" onClick={() => setAddresstype('Billing')}>Change</button>
                                </div>}
                                {currentbilling.length > 0 && <div className="collapse" id="collapseExample3">
                                    <div className="block p-6 rounded-lg shadow-lg bg-white">
                                        {billing && billing.map((value, index, array) => (
                                            <div className={`bg-gray-100 p-2 rounded-lg mb-2 gap-2 cursor-pointer relative ${currentbilling[0].uid === value.uid ? "border-1 border-black" : ""}`} key={index}>

                                                <div className="flex gap-2 mr-1 mb-2">
                                                    <FaPlusCircle data-bs-toggle="tooltip" data-bs-placement="top" title="Set  new billing address" onClick={() => {
                                                        setActivate("Add new")
                                                        setAddresstype("Billing")
                                                        AlertDialog({
                                                            title: "Add new address",
                                                            msg: "Scroll down to the form below to add a new " + addresstype + " address",
                                                            nbtn: "Close",
                                                            conf: false,

                                                        })
                                                    }} />
                                                    <FaCheckCircle data-bs-toggle="tooltip" data-bs-placement="top" title="Set as default billing address" onClick={() => CheckoutAddress(value)} />
                                                    <FaRegEdit data-bs-toggle="tooltip" data-bs-placement="top" title="Edit billing address" onClick={() => {
                                                        console.log(value.uid)
                                                        setAemail(value.email)
                                                        setAphone(value.phone)
                                                        setName(value.name)
                                                        setAddress(value.address)
                                                        setCountry(value.country)
                                                        setCity(value.city)
                                                        setZip(value.zip)
                                                        setActivate("Modify")
                                                        setUuid([value.id, value.uid])
                                                        AlertDialog({
                                                            title: "Edit address",
                                                            msg: "Scroll down to the form below to update your address",
                                                            nbtn: "Close",
                                                            conf: false,

                                                        })

                                                    }
                                                    } />
                                                    <FaTrash color="#ff0000" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete this address" 
                                                    onClick={()=>{
                                                        setUuid([value.id, value.uid])
                                                        AlertDialog({
                                                            title: "Delete address",
                                                            msg: "Are you sure you want to delete this address?",
                                                            conf: true,
                                                            nbtn: "Close",
                                                            ybtn: "Delete Address",
                                                            action: () => {
                                                                DeleteADoc(uuid[0], `active${addresstype.toLowerCase()}`)
                                                                DeleteADoc(uuid[1], addresstype.toLowerCase())
                                                                    .then((v) => {
                                                                        AlertDialog({
                                                                            title: "Success",
                                                                            msg: "addresses deleted successfully",
                                                                            conf: false,
                                                                            nbtn: "Close"
    
                                                                        })
                                                                        // window.location.reload()
                                                                    })
                                                                    .catch(err => {
                                                                        AlertDialog({
                                                                            title: "Error",
                                                                            msg: err.message.replace("Firebase: ", ""),
                                                                            conf: false,
                                                                            nbtn: "Close"
                                                                        })
                                                                    })
                                                            }
                                                        })
                                                    }}
                                                    />

                                                </div>
                                                <p className="text-sm">Name: {value.name}</p>
                                                <p className="text-sm">Email address: {value.email}</p>
                                                <p className="text-sm">Address: {value.address}</p>
                                                <p className="text-sm">Phone: +{info.code}{Formatter(value.phone)}</p>
                                                <p className="text-sm">Country: {value.country}</p>
                                                <p className="text-sm">City: {value.city}</p>
                                                <p className="text-sm">Zip code: {value.zip}</p>

                                                {currentbilling[0].uid === value.uid && <input className='absolute top-2 right-1 form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer' type="checkbox" checked name="chb" id={"ch" + index} />}
                                            </div>
                                        ))}
                                    </div>
                                </div>}

                                <div className="w-full p-4 border-1 border-gray-400 rounded-lg h-max mt-10">
                                    <h1 className="font-bold text-lg text-center">{activate} {addresstype} address</h1>
                                    <div className="w-full mt-10">
                                        <p className="text-lg mb-2">Full Name</p>
                                        <input className="px-4 py-2 mb-4 border-1 focus:shadow-md focus:shadow-bg-300 focus:border-2 border-gray-400 rounded-2xl outline-none w-full" type="text" name="fullname" id="name" value={name} ref={nameref} onChange={(e) => setName(e.target.value)} placeholder="ex.: John Doe" />

                                        <p className="text-lg mb-2">Email address</p>
                                        <input className="px-4 py-2 mb-4 border-1 focus:shadow-md focus:shadow-bg-300 focus:border-2 border-gray-400 rounded-2xl outline-none w-full" type="email" name="email" id="email" value={aemail} ref={aemailref} onChange={(e) => setAemail(e.target.value)} placeholder="ex.: johndoe@mail.com" />

                                        <p className="text-lg mb-2">Phone number</p>
                                        <input className="px-4 py-2 mb-4 border-1 focus:shadow-md focus:shadow-bg-300 focus:border-2 border-gray-400 rounded-2xl outline-none w-full" type="text" name="phone" id="phone" value={aphone} ref={aphoneref} onChange={(e) => setAphone(e.target.value)} placeholder="ex.: +(880) 5525-655" />

                                        <p className="text-lg mb-2">{addresstype} address</p>
                                        <input className="px-4 py-2 mb-4 border-1 focus:shadow-md focus:shadow-bg-300 focus:border-2 border-gray-400 rounded-2xl outline-none w-full" type="text" name="address" id="address" value={address} ref={addressref} onChange={(e) => setAddress(e.target.value)} placeholder="ex.: 423 Mahogany cresent Apt, suite 55U - DD5  " />

                                        <p className="text-lg mb-2">Country</p>
                                        <input className="px-4 py-2 mb-4 border-1 focus:shadow-md focus:shadow-bg-300 focus:border-2 border-gray-400 rounded-2xl outline-none w-full" type="text" name="country" id="country" value={country} ref={countryref} onChange={(e) => setCountry(e.target.value)} placeholder="ex.: United States of America" />

                                        <p className="text-lg mb-2">City/State</p>
                                        <input className="px-4 py-2 mb-4 border-1 focus:shadow-md focus:shadow-bg-300 focus:border-2 border-gray-400 rounded-2xl outline-none w-full" type="text" name="city" id="city" value={city} ref={cityref} onChange={(e) => setCity(e.target.value)} placeholder="ex.: Califonia CA" />

                                        <p className="text-lg mb-2">Zip code</p>
                                        <input className="px-4 py-2 mb-4 border-1 focus:shadow-md focus:shadow-bg-300 focus:border-2 border-gray-400 rounded-2xl outline-none w-full" type="text" name="confpass" id="confpass" value={zip} ref={zipref} onChange={(e) => setZip(e.target.value)} placeholder="ex.: 51255" />

                                        {addresstype === 'Shipping' && <p className="text-lg mb-2">Shipping Agent</p>}
                                        {addresstype === 'Shipping' &&
                                            <div className="flex flex-wrap gap-4 p-2 my-2">
                                                <div className={`form-check px-4 py-2 text-black font-bold rounded-lg bg-gray-200`}><input className='form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer' type="radio" name="cn" id="c1" value={'DHL'} onChange={(e) => setSa(e.target.value)} /><span> DHL</span></div>
                                                <div className={`form-check px-4 py-2 text-black font-bold rounded-lg bg-gray-200`}><input className='form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer' type="radio" name="cn" id="c2" value={'FedEx'} onChange={(e) => setSa(e.target.value)} /><span> FedEx</span></div>
                                                <div className={`form-check px-4 py-2 text-black font-bold rounded-lg bg-gray-200`}><input className='form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer' type="radio" name="cn" id="c3" value={'DSV'} onChange={(e) => setSa(e.target.value)} /><span> DSV</span></div>
                                                <div className={`form-check px-4 py-2 text-black font-bold rounded-lg bg-gray-200`}><input className='form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer' type="radio" name="cn" id="c4" value={'Expeditors'} onChange={(e) => setSa(e.target.value)} /><span> Expeditors</span></div>
                                                <div className={`form-check px-4 py-2 text-black font-bold rounded-lg bg-gray-200`}><input className='form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer' type="radio" name="cn" id="c5" value={'Panalpina'} onChange={(e) => setSa(e.target.value)} /><span> Panalpina</span></div>
                                                <div className={`form-check px-4 py-2 text-black font-bold rounded-lg bg-gray-200`}><input className='form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer' type="radio" name="cn" id="c6" value={'Ceva'} onChange={(e) => setSa(e.target.value)} /><span> Ceva</span></div>
                                                <div className={`form-check px-4 py-2 text-black font-bold rounded-lg bg-gray-200`}><input className='form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer' type="radio" name="cn" id="c7" value={'Kuehne + Nagel'} onChange={(e) => setSa(e.target.value)} /><span> Kuehne + Nagel</span></div>
                                                <div className={`form-check px-4 py-2 text-black font-bold rounded-lg bg-gray-200`}><input className='form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer' type="radio" name="cn" id="c8" value={'UPS Supply'} onChange={(e) => setSa(e.target.value)} /><span> UPS Supply</span></div>
                                                <div className={`form-check px-4 py-2 text-black font-bold rounded-lg bg-gray-200`}><input className='form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer' type="radio" name="cn" id="c9" value={'DB Schenker'} onChange={(e) => setSa(e.target.value)} /><span> DB Schenker</span></div>
                                            </div>}
                                        <button className="bg-black text-white text-lg px-6 py-2 rounded-full mt-4"
                                            onClick={() => {
                                                if (!name && name.length < 5) {

                                                    nameref.current.focus()
                                                    AlertDialog({
                                                        title: "Error",
                                                        msg: "Please enter your full name in the name input field",
                                                        conf: false,
                                                        nbtn: "Close"
                                                    })
                                                    return
                                                }

                                                if (/^(?:((([^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]'’,\-.\s])){1,}(['’,\-.]){0,1}){2,}(([^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]'’,\-. ]))*)$/.test(name)) {

                                                    nameref.current.focus()
                                                    AlertDialog({
                                                        title: "Error",
                                                        msg: "Please enter your last name and first name or other name in addition",
                                                        conf: false,
                                                        nbtn: "Close"
                                                    })
                                                    return
                                                }

                                                if (!phone) {

                                                    phoneref.current.focus()
                                                    AlertDialog({
                                                        title: "Error",
                                                        msg: "Please enter your phone number",
                                                        conf: false,
                                                        nbtn: "Close"
                                                    })
                                                    return
                                                }

                                                if (!aemail) {

                                                    aemailref.current.focus()
                                                    AlertDialog({
                                                        title: "Error",
                                                        msg: "Please enter your email address",
                                                        conf: false,
                                                        nbtn: "Close"
                                                    })
                                                    return
                                                }

                                                if (!(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/).test(aemail)) {

                                                    aemailref.current.focus()
                                                    AlertDialog({
                                                        title: "Error",
                                                        msg: "Please format your email address well",
                                                        conf: false,
                                                        nbtn: "Close"
                                                    })
                                                    return
                                                }
                                                if (aphone.length < 10) {

                                                    aphoneref.current.focus()
                                                    AlertDialog({
                                                        title: "Error",
                                                        msg: "Please make sure your phone number is accurate",
                                                        conf: false,
                                                        nbtn: "Close"
                                                    })
                                                    return
                                                }

                                                if (!address) {

                                                    addressref.current.focus()
                                                    AlertDialog({
                                                        title: "Error",
                                                        msg: `Please enter your ${addresstype} address`,
                                                        conf: false,
                                                        nbtn: "Close"
                                                    })
                                                    return
                                                }

                                                if (!country) {
                                                    countryref.current.focus()
                                                    AlertDialog({
                                                        title: "Error",
                                                        msg: "Please select your country",
                                                        conf: false,
                                                        nbtn: "Close"
                                                    })
                                                    return
                                                }
                                                if (!city) {
                                                    cityref.current.focus()
                                                    AlertDialog({
                                                        title: "Error",
                                                        msg: "Please enter your state and city",
                                                        conf: false,
                                                        nbtn: "Close"
                                                    })
                                                    return
                                                }
                                                if (!zip) {
                                                    zipref.current.focus()
                                                    AlertDialog({
                                                        title: "Error",
                                                        msg: "Please enter your zip code",
                                                        conf: false,
                                                        nbtn: "Close"
                                                    })
                                                    return
                                                }
                                                if (!sa && addresstype === "Shipping") {

                                                    AlertDialog({
                                                        title: "Error",
                                                        msg: "Please select a shipping agent to deliver your package",
                                                        conf: false,
                                                        nbtn: "Close"
                                                    })
                                                    return
                                                }

                                                if (activate === "Modify") {
                                                    AlertDialog({
                                                        title: "Modify address",
                                                        msg: "Are you sure you want to midify this address?",
                                                        conf: true,
                                                        nbtn: "Close",
                                                        ybtn: "Modify Address",
                                                        action: () => {
                                                            UpdateADoc(uuid[0], `active${addresstype.toLowerCase()}`, {
                                                                email,
                                                                phone,
                                                                id: email,
                                                                aphone:
                                                                name,
                                                                address,
                                                                country,
                                                                city,
                                                                zip,
                                                                sa
                                                            })
                                                            UpdateADoc(uuid[1], addresstype.toLowerCase(), {
                                                                email,

                                                                phone:
                                                                aphone,
                                                                name,
                                                                address,
                                                                country,
                                                                city,
                                                                zip,
                                                                sa
                                                            })

                                                                .then((v) => {
                                                                    AlertDialog({
                                                                        title: "Success",
                                                                        msg: "addresses modified successfully",
                                                                        conf: false,
                                                                        nbtn: "Close"

                                                                    })
                                                                    // window.location.reload()
                                                                })
                                                                .catch(err => {
                                                                    AlertDialog({
                                                                        title: "Error",
                                                                        msg: err.message.replace("Firebase: ", ""),
                                                                        conf: false,
                                                                        nbtn: "Close"
                                                                    })
                                                                })
                                                        }
                                                    })
                                                }
                                                else {
                                                    AlertDialog({
                                                        title: "Add address",
                                                        msg: "Are you sure you want to add this address?",
                                                        conf: true,
                                                        nbtn: "Close",
                                                        ybtn: "Add Address",
                                                        action: () => {
                                                            const uid = UID(15)

                                                            SetADoc(`active${addresstype.toLowerCase()}`, email, {
                                                                email: email,
                                                                uid: email,

                                                                phone: aphone,
                                                                addresstype: addresstype,
                                                                name: name,
                                                                address: address,
                                                                country: country,
                                                                city: city,
                                                                zip: zip,
                                                                active: false,
                                                                agent: sa
                                                            })
                                                            SetADoc(addresstype.toLowerCase(), uid, {
                                                                email: email,
                                                                id: email,
                                                                uid: uid,
                                                                phone: aphone,
                                                                addresstype: addresstype,
                                                                name: name,
                                                                address: address,
                                                                country: country,
                                                                city: city,
                                                                zip: zip,
                                                                active: false,
                                                                agent: sa
                                                            })
                                                                .then((v) => {
                                                                    AlertDialog({
                                                                        title: "Success",
                                                                        msg: "The new address has been added to " + addresstype + " addresses successfully",
                                                                        conf: false,
                                                                        nbtn: "Close"

                                                                    })
                                                                    if (currentshipping.length < 1 || currentshipping.length < 1) {
                                                                        window.location.reload()
                                                                    }
                                                                })
                                                                .catch(err => {
                                                                    AlertDialog({
                                                                        title: "Error",
                                                                        msg: err.replace("Firebase: ", ""),
                                                                        conf: false,
                                                                        nbtn: "Close"
                                                                    })
                                                                })
                                                        }
                                                    })
                                                }


                                                /*  const uid = UID(15)
 
                                                 CheckoutAddress({
                                                     email: info.email,
                                                     uid,
                                                     id: email,
                                                     phone: info.code + aphone,
                                                     addresstype: addresstype,
                                                     name: name,
                                                     address: address,
                                                     country: country,
                                                     city: city,
                                                     zip: zip,
                                                     active: false,
                                                     agent: sa
                                                 }) */

                                            }}
                                        >{activate} {addresstype} address</button>

                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className="hidden lg:block mx-2 border-1 border-gray-100" />

                        <div className="flex flex-col w-full lg:w-1/2 h-max justify-between gap-4 ">
                            {FullCart(cartitems).length > 0 ? <div className="w-full">

                                {FullCart(cartitems).map((value, index, array) => {

                                    return (
                                        <div className="flex w-full  gap-4 mt-10 h-max" key={index}>
                                            <img src={value.img} alt={value.brand} className="object-contain h-32 w-28 bg-gray-200 p-2 rounded-lg" />
                                            <div className="flex flex-col w-full h-max justify-between">
                                                <div className="flex flex-col w-full gap-1">
                                                    <div className="flex w-full justify-between gap-4">
                                                        <p className="font-bold text-md line-clamp-2">{value.displayname}</p>

                                                        <div className="px-2 rounded-lg border-2 border-green-600 text-green-600 h-max">{Mformatter(PriceD(value) * cartitems[index].qty)}</div>
                                                    </div>
                                                    <div className="flex gap-4 items-center text-gray-400">
                                                        <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none"><path d="M7.01 18.0001L3 13.9901C1.66 12.6501 1.66 11.32 3 9.98004L9.68 3.30005L17.03 10.6501C17.4 11.0201 17.4 11.6201 17.03 11.9901L11.01 18.0101C9.69 19.3301 8.35 19.3301 7.01 18.0001Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M8.35 1.94995L9.69 3.28992" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M2.07 11.92L17.19 11.26" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M3 22H16" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M18.85 15C18.85 15 17 17.01 17 18.24C17 19.26 17.83 20.09 18.85 20.09C19.87 20.09 20.7 19.26 20.7 18.24C20.7 17.01 18.85 15 18.85 15Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                        <p className="text-md">Color Variety</p>
                                                        <p className="text-md">|</p>
                                                        <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none"><path d="M21 9V3H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M3 15V21H9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M21 3L13.5 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10.5 13.5L3 21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </svg>
                                                        <p className="font-bold">{cartitems[index].qty}</p>
                                                    </div>
                                                </div>

                                                <div className="flex justify-between">
                                                    <div className="border-1 border-gray-400 px-4 py-1 text-sm text-gray-400 flex gap-2 rounded-full items-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-3.5 h-3.5"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                                                        <p className="">in stock</p>
                                                    </div>
                                                    <p className="text-blue-600 font-bold cursor-pointer"
                                                        onClick={() => Edit(cartitems[index].id, cartitems[index].qty)}
                                                    >Edit Item</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div> : (<div className="flex flex-col items-center justify-center px-6">
                                <img src={emptycart} alt="emptycart" className="" />
                                <p className="font-bold mx-6 mb-4">Your cart is Empty</p>
                            </div>)}



                            <div className="hidden lg:flex border-r-1 border-gray-200"></div>
                            <div className="flex w-full h-max flex-col gap-4 mt-4 lg:mt-10">
                                <h1 className="font-bold text-lg">Checkout Summary</h1>
                                <div className="justify-between flex border-b-1 border-b-gray-200 py-2">
                                    <p className="text-sm text-gray-400">Subtotal</p>
                                    <p className="text-sm text-black font-bold">{Mformatter((totalbal))}</p>
                                </div>
                                <div className="justify-between flex border-b-1 border-b-gray-200 py-2">
                                    <p className="text-sm text-gray-400">Shipping estimate</p>
                                    <p className="text-sm text-black font-bold">{Mformatter((totalbal * 0.025))}</p>
                                </div>
                                <div className="justify-between flex border-b-1 border-b-gray-200 py-2">
                                    <p className="text-sm text-gray-400">Tax estimate</p>
                                    <p className="text-sm text-black font-bold">{Mformatter((totalbal * 0.002))}</p>
                                </div>
                                {/*  */}
                                <div className="justify-between items-center flex border-b-gray-200 py-2">
                                    <p className="text-xl font-bold text-black">Order total</p>
                                    <p className="text-2xl text-black font-bold">{Mformatter(((totalbal + (totalbal * 0.025) + (totalbal * 0.002))))}</p>
                                </div>

                                {FullCart(cartitems).length > 0 && <button className="w-full text-center text-white bg-black hover:shadow-md text-lg rounded-full py-2 mt-10"
                                    onClick={() => {
                                        try {

                                            if (currentshipping.length < 1) {
                                                AlertDialog({
                                                    title: "Checkout error",
                                                    msg: "Please ensure you have added an active shipping address. Add one from your account page.",
                                                    conf: false,
                                                    nbtn: "Close",

                                                })
                                                return
                                            }
                                            if (currentbilling.length < 1) {
                                                AlertDialog({
                                                    title: "Checkout error",
                                                    msg: "Please ensure you have added an active billing address. Add one from your account page.",
                                                    conf: false,
                                                    nbtn: "Close",

                                                })
                                                return
                                            }


                                            var uid = UID(12).toUpperCase()
                                            var date = moment().format('ll')
                                            var traking = UID(8).toUpperCase()
                                            var order = {
                                                orderno: uid,
                                                items: checkoutitems,
                                                date,
                                                email: account.email,
                                                shipping: currentshipping[0].uid,
                                                billing: currentbilling[0].uid,
                                                traking,
                                                amount: parseFloat((totalbal + (totalbal * 0.025) + (totalbal * 0.002)).toFixed(2))
                                            }
                                            var items = []
                                            FullCart(cartitems).map((v, i, a) => items.push({
                                                name: v.displayname,
                                                qty: cartitems[i].qty,
                                                price: Mformatter(PriceD(v) * cartitems[i].qty),
                                                img: v.img
                                            }))
                                            var ordertoemail = {
                                                username: account.name,
                                                phone: Mformatter(account.phone),
                                                address: account.address,
                                                country: account.country,
                                                city: account.city,
                                                orderno: uid,
                                                items,
                                                date,
                                                email: account.email,
                                                shipping: currentshipping[0],
                                                billing: currentbilling[0],
                                                traking,
                                                amount: parseFloat((totalbal + (totalbal * 0.025) + (totalbal * 0.002)).toFixed(2))
                                            }

                                            const tp = parseFloat(((totalbal + (totalbal * 0.025) + (totalbal * 0.002))).toFixed(2))
                                            var balance = parseFloat((account.balance).toFixed(2))

                                            if (balance > tp) {

                                                /*  console.log(deleteitems)
                                                 console.log(checkoutitems)
     
                                                 console.log(currentshipping.length)
                                                 console.log(currentbilling.length)*/

                                                AlertDialog({
                                                    title: "Checkout order",
                                                    msg: "Both shipping and billing addresses provided would be used by your shipping agent to get your order(s) delivered, are you sure the addresses provided are valid and wish to checking out this order?",
                                                    conf: true,
                                                    nbtn: "Close",
                                                    ybtn: "Checkout",
                                                    action: () => {
                                                        UpdateADoc(account.email, 'accounts', { balance: parseFloat((balance - tp).toFixed(2)) })
                                                        Progress("Please wait as we help you checkout your order", prog)

                                                        SetADoc("orders", Date.now().toString(), order)
                                                            .then((v) => {
                                                                setProg(true)

                                                                setTimeout(() => {
                                                                    SendEmail(ordertoemail)

                                                                    DeleteMutipleDocs("cart", deleteitems)
                                                                    AlertDialog({
                                                                        title: "Checkout Success",
                                                                        msg: "Your order has been placed successfully. Item(s) would be recieved in 15 days time. Head to your account and see more of your order details.",
                                                                        conf: true,
                                                                        ybtn: "Close",
                                                                        action: () => navigate('/', { replace: true })
                                                                    })
                                                                }, 500);


                                                            })

                                                    }
                                                })
                                                return
                                            }

                                            AlertDialog({
                                                title: "Checkout error",
                                                msg: "Please your account balance is not sufficient to complete this order. Topup your account to continue",
                                                conf: true,
                                                nbtn: "Cancel",
                                                ybtn: "Topup balance",
                                                action: () => {
                                                    setTimeout(() => {
                                                        PaymentDialog(navigate)
                                                    }, 500);
                                                }
                                            })


                                        } catch (error) {
                                            console.log(error)
                                        }
                                    }
                                    }
                                >Confirm order </button>}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div >

    )
}

export default CheckoutComp