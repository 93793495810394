/* eslint-disable react-hooks/rules-of-hooks */
import { useLayoutEffect, useEffect, useRef, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useNavigate } from 'react-router-dom';
import { Discount, Mformatter, PaymentAPI, PriceD, PriceDistribution, Quotient } from '../../config/function';
import '../../react-confirm-alert.css';
import rate from '../../images/rate.svg'
import parse from 'html-react-parser'
import { UID } from '../../config/configdata';
import { DeleteADoc, SetADoc, UpdateADoc } from '../../config/db';
import { images } from '../../data/mdata';

export const AlertDialog = (list) => {
    confirmAlert({
        title: "Confirm Dialog",
        overlayClassName: "overlay-custom-className-name",
        customUI: ({ title, onClose }) => {
            return (
                <div className='mx-auto p-4 max-w-md rounded-lg border-1 border-gray-400 bg-white w-ful m-6 lg:w-max lg:h-max ' style={{ zIndex: '99999' }}>
                    <h1 className='font-bold text-lg mb-5'>{list.title}</h1>

                    <p className='mb-3'>{list.msg}</p>
                    <div className="flex gap-4 justify-end p-2">

                        <button className='font-medium' onClick={onClose}>{list.nbtn}</button>
                        {list.conf && (
                            <button
                                className='font-bold'
                                onClick={() => {
                                    list.action();
                                    onClose();
                                    onClose();
                                }}
                            >
                                {list.ybtn}
                            </button>)}
                    </div>
                </div>
            );
        }
    });
}
export const PaymentDialog = (navigate) => {

    confirmAlert({
        title: "Confirm Dialog",
        overlayClassName: "overlay-custom-className-name",
        customUI: ({ title, onClose }) => {

            return (
                <Pay nav={navigate} close={onClose} />
            );
        }
    });

}

export const Progress = (message, done) => {
    setTimeout(() => {
        confirmAlert({
            overlayClassName: "overlay-custom-className-name",
            customUI: ({ onClose }) => {
                return (<div className='mx-auto p-4 rounded-lg border-1 border-gray-400 bg-white w-5/6 lg:w-max lg:h-max'>
                    <div className="flex justify-center items-center">
                        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full mb-3" role="status">
                        </div>
                    </div>
                    <p className='mb-3'>{message}</p>
                </div>)
            }
        });
    }, 500);
}



const Pay = ({ nav, close }) => {
    const amtRef = useRef();
    const [amt, setAmt] = useState(100);
    const [amtc, setAmtc] = useState(0);

    return (
        <div className='flex flex-col mx-auto p-4 rounded-lg border-1 border-gray-400 bg-white w-5/6 lg:w-max lg:h-max items-center'>
            <h1 className='font-bold text-lg mb-5'>Balance Topup</h1>

            <p className='mb-3'>Do you want to top up your account? confirm by setting your amount below</p>
            <div className="flex flex-col gap-2 justify-center">
                <dif className="flex gap-2 jusityfy-center">

                    <div className={`${amtc === 1 ? "bg-red-100" : ""} flex gap-1 py-2 px-4 rounded-lg border-1 focus:border-red-600 border-gray-500 items-center w-max`} ref={amtRef} >
                        <button type="text" onClick={(e) => { setAmt(100); setAmtc(1) }}>$100</button>
                    </div>
                    <div className={`${amtc === 2 ? "bg-red-100" : ""} flex gap-1 py-2 px-4 rounded-lg border-1 focus:border-red-600 border-gray-500 items-center w-max`} ref={amtRef} >
                        <button type="text" onClick={(e) => { setAmt(200); setAmtc(2) }}>$200</button>
                    </div>
                    <div className={`${amtc === 3 ? "bg-red-100" : ""} flex gap-1 py-2 px-4 rounded-lg border-1 focus:border-red-600 border-gray-500 items-center w-max`} ref={amtRef} >
                        <button type="text" onClick={(e) => { setAmt(500); setAmtc(3) }}>$500</button>
                    </div>
                    <div className={`${amtc === 4 ? "bg-red-100" : ""} flex gap-1 py-2 px-4 rounded-lg border-1 focus:border-red-600 border-gray-500 items-center w-max`} ref={amtRef} >
                        <button type="text" onClick={(e) => { setAmt(1000); setAmtc(4) }}>$1000</button>
                    </div>
                </dif>

                <div className="flex gap-1 py-2 px-4 rounded-lg border-1 focus:border-red-600 border-gray-500 items-center w-full" ref={amtRef} >

                    <input className='outline-none' type="text" placeholder='Enter minimum of $100' name="amount" id="amount" onChange={(e) => setAmt(e.target.value)} />
                </div>
            </div>
            <div className="flex gap-4 justify-end p-2 mt-5">

                <button className='font-medium' onClick={close}>Cancel</button>


                <button
                    className='font-bold'
                    onClick={() => {
                        if (amt < 100) {

                            amtRef.current.focus();
                            AlertDialog({
                                title: "Payment Error",
                                msg: "Please Enter a minimum amount of $100",
                                conf: false,
                                nbtn: "I understand"
                            })
                            return
                        }
                        close();
                        Progress("Please wait whiles we initialize your payment")
                        PaymentAPI(amt)
                            .then((v) => v.json())
                            .then((v) => {
                                close();
                                console.log(v.data.code)
                                console.log(v.data)
                                nav('/mypaymentpage', { state: { code: v.data.code } });
                            })
                    }}>
                    Continue
                </button>

            </div>
        </div>
    )
}



export const QuickView = ({ object }) => {

    var [qty, setQty] = useState(1);
    const [amz, setAmz] = useState([])
    var account = JSON.parse(localStorage.getItem('account'))

    useEffect(() => {
        if (object.asin !== '') {
            const pimg = images.filter((value, index, array) => value.asin === object.asin)
            //console.log(pimg[0].imgs)
            setAmz(pimg[0].imgs ?? [])
        }


    }, [amz, object.asin])
    return (

        <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
            id="quickview" tabIndex="-1" aria-labelledby="quickviewlabel" aria-modal="true" role="dialog">
            {object && (<div className="modal-dialog modal-xl modal-dialog-scrollable relative w-auto pointer-events-none">
                <div className="modal-content border-none shadow-lg relative flex flex-col   pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                    <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">

                        <button type="button"
                            className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                            data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="flex flex-col lg:flex-row modal-body relative rounded-xl z-50 lg:m-8 w-full gap-8 px-4">
                        <div className="w-full lg:w-2/5 lg:sticky lg:top-0 ">

                            <div className="flex justify-start">
                                <img className="w-full  lg:h-96 object-cover lg:object-contain rounded-xl" src={object.img} alt={object.name} />
                            </div>
                            {object && (<div className="grid grid-cols-5 mr-6 mt-6 gap-2">
                                {object.imgs.map((value, index, array) => (
                                    <img className="h-12 w-16  cursor-pointer hover:bg-slate-500" src={value.img} alt={value.name} key={index} />
                                ))}
                            </div>)}

                            {amz && (<div className="grid grid-cols-5 mr-6 mt-6 gap-2">
                                {amz.map((value, index, array) => (
                                    <img className="h-18 w-16 cursor-pointer hover:bg-slate-500" src={value.img} alt={value.name} key={index} />
                                ))}
                            </div>)}

                        </div>
                        <div className="w-full lg:w-3/5 mr-8">
                            <h1 className="text-lg font-bold">{object.displayname}</h1>
                            <h1 className="text-sm my-4 line-clamp-4">{object.short.replace('Jumia', 'Picolocal')}</h1>
                            <div className="flex mt-6 gap-6 items-center">
                                <button className="border-2 border-green-600 h-max rounded-lg bg-white px-2 text-green text-lg font-bold text-green-600 hover:bg-green-200">{Mformatter(PriceD(object) * qty)}</button>
                                <div className="flex flex-col items-center">
                                    <p className="font-bold text-black rounded-full bg-gray-200 w-max h-max py-1 px-3">-{Discount((object.price / Quotient), PriceD(object))} off</p>
                                    <p className="font-sm font-bold line-through">{Mformatter(object.price / Quotient)}</p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <img src={rate} alt="rate" className="h-4" />
                                    <p className="text-sm">{object.rate} ({object.review} reviews)</p>
                                </div>
                            </div>
                            <div className="flex gap-8 mt-16 justify-between items-center h-max">
                                <div className="flex gap-4 items-center h-max">
                                    <p className="p-2 rounded-full hover:bg-gray-50 h-8 w-8 border-1 border-gray-400 cursor-pointer text-center text-md font-medium flex items-center justify-center"
                                        onClick={() => qty > 1 && setQty(--qty)}
                                    >-</p>
                                    <p className=" text-md font-medium flex justify-center">{qty}</p>
                                    <p className="p-2 rounded-full hover:bg-gray-50 h-8 w-8 border-1 border-gray-400 cursor-pointer text-center text-md font-medium flex items-center justify-center"
                                        onClick={() => qty < 10 && setQty(++qty)}
                                    >+</p>
                                </div>
                                <button className="w-full px-6 py-4 rounded-full bg-black text-white font-extrabold text-lg"
                                    onClick={() => {
                                        AlertDialog({
                                            title: "Add To Cart",
                                            msg: "Do you want to add this item to your cart?",
                                            conf: true,
                                            nbtn: "Cancel",
                                            ybtn: "Yes, Continue",
                                            action: () => {

                                                var item = {
                                                    id: Date.now().toString(),
                                                    email: account.email,
                                                    qty: qty,
                                                    uid: object.uid,
                                                    price: PriceD(object)
                                                }
                                                /*  var lc = JSON.parse(localStorage.getItem('cartlocal'));
                                                 console.log(lc)
                                                 if(lc === null){
                                                     localStorage.setItem('cartlocal')
                                                 }
                                                 lc.push(item)
                                                 localStorage.setItem('cartlocal', JSON.stringify(lc)) */
                                                setQty(1)
                                                SetADoc('cart', item.id, item)
                                                    .then((res) => {
                                                        AlertDialog({
                                                            title: "Success",
                                                            msg: "Item has been successfully added to your cart",
                                                            conf: false,
                                                            nbtn: "Okay",
                                                        })
                                                    })
                                            }
                                        })
                                    }}
                                >Add to cart</button>
                            </div>
                            <div className="flex flex-col mt-8">
                                <h1 className="font-bold">DESCRIPTION</h1>
                                {parse(object.desc.replace('Jumia', 'Picolocal'))}
                            </div>
                        </div>
                    </div>

                </div>
            </div>)}
        </div>)
}
export const Edit = (id, qty) => {

    confirmAlert({
        title: "Confirm Dialog",
        overlayClassName: "overlay-custom-className-name",
        customUI: ({ title, onClose }) => {
            return (
                <Modifyitem id={id} quantity={qty} close={onClose} />
            );
        }
    });

}

export const Checkout = (nav) => {

    confirmAlert({
        title: "Confirm Dialog",
        overlayClassName: "overlay-custom-className-name",
        customUI: ({ title, onClose }) => {
            return (
                <CheckoutComp navigate={nav} close={onClose} />
            );
        }
    });

}
export const QV = (object) => {

    confirmAlert({
        title: "Confirm Dialog",
        overlayClassName: "overlay-custom-className-name",
        customUI: ({ title, onClose }) => {
            return (
                <QVE object={object} />
            );
        }
    });

}

const QVE = ({ object }) => {
    var [qty, setQty] = useState(1);
    var account = JSON.parse(localStorage.getItem('account'))
    return (
        <div className='flex justify-cener h-max' style={{ maxHeight: '80vh' }}>
            <div className="modal-dialog modal-xl modal-dialog-scrollable relative w-auto pointer-events-none" >
                {object && (<div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">

                    <div className="flex flex-col lg:flex-row modal-body relative rounded-xl  lg:m-8 w-full gap-8 px-4">
                        <div className="w-full lg:w-2/5">

                            <div className="flex justify-start">
                                <img className="w-full object-cover lg:object-contain bg-slate-100 p-4 rounded-xl" src={object.img} alt={object.name} />
                            </div>

                            {object && (<div className="grid grid-cols-3 mr-6 mt-6 gap-2">
                                {object.imgs.map((value, index, array) => (
                                    <img className="cursor-pointer hover:bg-slate-500" src={value.img} alt={value.name} key={index} />
                                ))}
                            </div>)}

                        </div>
                        <div className="w-full lg:w-3/5 mr-8">
                            <h1 className="text-lg font-bold">{object.displayname}</h1>
                            <h1 className="text-sm my-4">{object.short.replace('Jumia', 'Picolocal')}</h1>
                            <div className="flex mt-6 gap-6">
                                <button className="border-2 border-green-600 rounded-lg bg-white px-2 text-green text-lg font-bold text-green-600 hover:bg-green-200">{PriceD(object) * qty}</button>
                                <div className="flex gap-2 items-center">
                                    <img src={rate} alt="rate" className="h-4" />
                                    <p className="text-sm">{object.rate} ({object.review} reviews)</p>
                                </div>
                            </div>
                            <div className="flex gap-8 mt-16 justify-between items-center h-max">
                                <div className="flex gap-4 items-center h-max">
                                    <p className="p-2 rounded-full hover:bg-gray-50 h-8 w-8 border-1 border-gray-400 cursor-pointer text-center text-md font-medium flex items-center justify-center"
                                        onClick={() => qty > 1 && setQty(--qty)}
                                    >-</p>
                                    <p className=" text-md font-medium flex justify-center">{qty}</p>
                                    <p className="p-2 rounded-full hover:bg-gray-50 h-8 w-8 border-1 border-gray-400 cursor-pointer text-center text-md font-medium flex items-center justify-center"
                                        onClick={() => qty < 10 && setQty(++qty)}
                                    >+</p>
                                </div>
                                <button className="w-full px-6 py-4 rounded-full bg-black text-white font-extrabold text-lg"
                                    onClick={() => {
                                        AlertDialog({
                                            title: "Add To Cart",
                                            msg: "Do you want to add this item to your cart?",
                                            conf: true,
                                            nbtn: "Cancel",
                                            ybtn: "Yes, Continue",
                                            action: () => {

                                                var item = {
                                                    id: Date.now().toString(),
                                                    email: account.email,
                                                    qty: qty,
                                                    uid: object.uid,
                                                    price: (((((object.price / Quotient) - ((object.price / Quotient) * (object.discount / 100))) * qty).toFixed(2)))
                                                }
                                                var lc = JSON.parse(localStorage.getItem('cartlocal'));
                                                lc.push(item)
                                                localStorage.setItem('cartlocal', JSON.stringify(lc))
                                                setQty(1)
                                                console.log(item)
                                                SetADoc('cart', item.id, item)
                                                    .then((res) => {
                                                        AlertDialog({
                                                            title: "Success",
                                                            msg: "Item " + object.displayname + " successfully added to your cart?",
                                                            conf: false,
                                                            nbtn: "Okay",
                                                        })
                                                    })
                                            }
                                        })
                                    }}
                                >Add to cart</button>
                            </div>
                            <div className="flex flex-col mt-8">
                                <h1 className="font-bold">DESCRIPTION</h1>
                                {parse(object.desc.replace('Jumia', 'Picolocal'))}
                            </div>
                        </div>
                    </div>

                </div>)}
            </div>
        </div>
    )
}
export const Modifyitem = ({ close, id, quantity }) => {
    const [def, setDef] = useState(quantity);
    var [qty, setQty] = useState(quantity);
    return (
        <div className='flex flex-col mx-auto p-4 rounded-lg border-1 border-gray-400 bg-white w-full lg:w-max lg:h-max items-center'>
            <h1 className='font-bold text-lg mb-5'>Modify Your Item</h1>

            <p className='mb-3'>Please choose your preferred action. Delete or update item</p>
            <div className="flex gap-4 justify-center items-center h-max my-4">
                <p className="font-bold">Item Quanity:</p>
                <p className="p-2 rounded-full hover:bg-gray-50 h-8 w-8 border-1 border-gray-400 cursor-pointer text-center text-md font-medium flex items-center justify-center" onClick={() => qty > 1 && setQty(--qty)}>-</p>
                <p className=" text-md font-medium flex justify-center">{qty}</p>
                <p className="p-2 rounded-full hover:bg-gray-50 h-8 w-8 border-1 border-gray-400 cursor-pointer text-center text-md font-medium flex items-center justify-center" onClick={() => qty < 10 && setQty(++qty)}>+</p>
            </div>

            <div className="flex gap-4 justify-end p-2 mt-2">
                <button className='font-medium' onClick={close}>Cancel</button>

                <button
                    className='font-bold'
                    onClick={() => {

                        close();
                        AlertDialog({
                            title: def === qty ? 'Remove Item' : "Update item",
                            msg: def === qty ? "Do you wish to delete this item from your cart?" : "Do you wish to update this item in your cart?",
                            conf: true,
                            nbtn: "Cancel",
                            ybtn: def === qty ? 'Remove' : "Update",
                            action: () => {
                                def === qty ? DeleteADoc(id, "cart")
                                    .then((v) => {
                                        close();
                                        AlertDialog({
                                            title: "Success",
                                            msg: "Your item has been successfully deleted from your cart ",
                                            nbtn: "Close",
                                            conf: false,

                                        })
                                    })
                                    : UpdateADoc(id, "cart", { qty })
                                        .then((v) => {
                                            close()
                                            AlertDialog({
                                                title: "Success",
                                                msg: "Your item has been updated successfully",
                                                nbtn: "Close",
                                                conf: false,

                                            })
                                        })
                            }
                        })

                    }}>{def === qty ? 'Remove Item' : "Update"}

                </button>

            </div>
        </div>
    )
}


const CheckoutComp = ({ close, navigate }) => {


}


