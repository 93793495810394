import { useLayoutEffect, useState, useRef } from 'react'
import { BiMoney, BiUser, BiVoicemail, BiCurrentLocation } from 'react-icons/bi'
import { FaWallet } from 'react-icons/fa'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../../config/configdata'
import { GetADoc } from '../../config/db'
import { Tab1 } from './tab1'
import { Tab2 } from './Tab2'
import Tab3 from './Tab3';
import Tab4 from './Tab4';
import { PaymentDialog } from '../alert/alert'
import { useNavigate } from 'react-router-dom'

import { Mformatter } from '../../config/function'


export const Account = () => {
    const [login, setLogin] = useState(false);
    const [info, setInfo] = useState({})
    const [acc, setAcc] = useState([]);
    const navigate = useNavigate();

    var account = JSON.parse(localStorage.getItem('account'))

    useLayoutEffect(() => {
        setInfo(account)
        setAcc([account])

    }, []) 


    return (
        <div className="w-full flex lg:w-4/6 mx-auto mt-10 px-4">
            {acc.length > 0 && <div className="flex flex-col mx-auto w-full lg:w-5/6">
                <div className="my-10"><h1 className="font-bold text-4xl mb-10">Account</h1>
                    <div className="flex flex-col md:flex-row gap-4 mt-2 lg:items-center">
                        <div className="flex gap-2 items-center"><BiUser size={20} /><p className="font-medium text-lg">{info.name}</p></div>
                        <div className="flex gap-2 items-center"><BiVoicemail size={20} /><p className="text-md">{info.email}</p></div>
                        <div className="flex gap-2 items-center"><BiCurrentLocation size={20} /><p className="text-md">{info.address}</p></div>
                    </div>
                    <div className="flex gap-2 items-center mt-4"><FaWallet size={20} />
                        Balance:<h1 className="font-extrabold text-2xl">{Mformatter(parseInt(info.balance))}</h1>

                    </div>
                    <button className="w-max rounded-full px-6 py-2 bg-black text-white mt-5" onClick={() => PaymentDialog(navigate)}>Topup Balance</button>
                </div>

                <ul className="nav nav-tabs flex flex-wrap list-none border-b-0 pl-0 mb-4 mt-5 z-10" id="tabs-tab"
                    role="tablist">
                    <li className="nav-item" role="presentation">
                        <a href="#account-info" className=" nav-link block font-medium  leading-tight  border-x-0 border-t-0 border-b-2 border-transparent px-6 pb-8 text-sm pt-2 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent active" id="account-info-tab" data-bs-toggle="pill" data-bs-target="#account-info" role="tab" aria-controls="account-info"
                            aria-selected="true">Account info.</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a href="#wish-list" className=" nav-link block font-medium  leading-tight  border-x-0 border-t-0 border-b-2 border-transparent px-6 pb-8 text-sm pt-2 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent" id="wish-list-tab" data-bs-toggle="pill" data-bs-target="#wish-list" role="tab"
                            aria-controls="wish-list" aria-selected="false">Wishlist</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a href="#my-order" className=" nav-link block font-medium  leading-tight  border-x-0 border-t-0 border-b-2 border-transparent px-6 pb-8 text-sm pt-2 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent" id="my-order-tab" data-bs-toggle="pill" data-bs-target="#my-order" role="tab"
                            aria-controls="my-order" aria-selected="false">My Order</a>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                        <a href="#billing" className=" nav-link block font-medium  leading-tight  border-x-0 border-t-0 border-b-2 border-transparent px-6 pb-8 text-sm pt-2 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent" id="billing-tab" data-bs-toggle="pill" data-bs-target="#billing" role="tab"
                            aria-controls="billing" aria-selected="false">Shipping - Billing address</a>
                    </li> */}

                </ul>
                <div className="tab-content mt-5" id="tabs-tabContent">
                    <div className="tab-pane fade show active w-full" id="account-info" role="tabpanel" aria-labelledby="account-info-tab">
                        <Tab1 list={info} />
                    </div>
                    <div className="tab-pane fade show" id="wish-list" role="tabpanel" aria-labelledby="wish-list-tab">
                        <Tab2 list={info} />
                    </div>

                    <div className="tab-pane fade show" id="my-order" role="tabpanel" aria-labelledby="my-order-tab">
                        <Tab3 list={info} />
                    </div>

                    {/* <div className="tab-pane fade show" id="billing" role="tabpanel" aria-labelledby="billing-tab">
                        <Tab4 list={info} />
                    </div> */}

                </div>
            </div>}
        </div>)
}