import { useLayoutEffect, useRef, useState } from 'react'
import { Link, NavLink } from "react-router-dom"
import cart from '../../images/cart.svg'
import search from '../../images/search.svg'
import searchb from '../../images/searchb.svg'
import user from '../../images/user.svg'
import menu from '../../images/menu.svg'
import close from '../../images/close.svg'
import twitter from '../../images/twitter.svg'
import facebook from '../../images/facebook.svg'
import instagram from '../../images/instagram.svg'
import telegram from '../../images/telegram.svg'
import likeoff from '../../images/likeoff.svg'
import rate from '../../images/rate.svg'
import {
    ArrivalData,
    BestsellerData,
    CaroucelData,
    CaroucelImgs,
    CategoriesData,
    ChosenData,
    DiscoverData,
    FootElements,
    ItemsData,
    MiniCart,
    WalkthoughData,
    CaroucelImgsSm
} from '../../data/data_items';
import { FaUser, FaHeart, FaShoppingBasket, FaSignOutAlt, FaQuestion, FaFacebook, FaInstagram, FaTwitter, FaTelegram, FaYoutube } from 'react-icons/fa'
import { auth } from '../../config/configdata'
import { onAuthStateChanged } from 'firebase/auth'
import { DeleteADoc, GetADoc, GetADocWhere, SetADoc } from '../../config/db'
import { AlertDialog, Progress, QuickView, PaymentDialog } from '../alert/alert';
import { Mformatter, Item, Logout, PriceDistribution, PriceD, BnC, Formatter, IMGS, SendEmail, AddToCartAction, Quotient, Counter } from '../../config/function';
import '../../index.css'
import { where } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import emptycart from '../../images/emptycart.png'
import { confirmAlert } from 'react-confirm-alert'
import { ProductData } from '../../data/pdata';
import bf from '../../images/blackfriday.png'
import { Rating } from '../../config/function'

var asin = []
ProductData().map((value, index, array) => {
    if (value.asin !== "") {
        asin.push(value.asin)
    }
})
//console.log(asin)

var acc = localStorage.getItem('account')
if (acc === null || acc === 'undefined') {
    localStorage.setItem('account', JSON.stringify([]))
}

export const Navbar = () => {
    const [searchItem, setSearchItem] = useState(false);
    const [cartno, setCartno] = useState(0);
    const [cartbtn, setCartbtn] = useState(null);
    const [userbtn, setUserbtn] = useState(null);

    const [login, setLogin] = useState(false);
    const [ls, setLs] = useState(false);
    const [info, setInfo] = useState({})
    const [cartitems, setCartitems] = useState([])
    const navigate = useNavigate();
    const [totalbal, setTotalbal] = useState(0)
    const [searchitem, setSearchitem] = useState('')
    const searchRef = useRef()
    const msearchRef = useRef()

    var prices = 0;

    //CheckAccount();
    var account = JSON.parse(localStorage.getItem('account'))




    const keypress = (event) => {
        if (event.key === 'Enter') {
            searchAction();
        }
    }

    const searchAction = (ref) => {
        if (searchitem.length < 1) {
            ref.current.focus()
            AlertDialog({
                title: "Error",
                msg: "Please enter something longer than that",
                conf: false,
                nbtn: "Close"
            })
            return;
        }

        navigate('/searchitempage', { state: { query: searchitem } })

    }
    const search2Action = (ref) => {



    }

    if (account) {
        GetADoc("accounts", account.email).then(v => {
            localStorage.setItem('account', JSON.stringify(v.data()))
            setInfo(v.data())
        }).catch(err => { /* window.location.reload()  */ })
    }



    useLayoutEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setLs(true)
                if (ls && account) {
                    setLogin(true)
                }
                GetADoc("accounts", user.email).then(v => {
                    localStorage.setItem('account', JSON.stringify(v.data()))
                    setInfo(v.data())
                })
            }
        })


    }, [info.email, ls])
    if (ls && account) {

        GetADocWhere('cart', where('email', '==', account.email))
            .then((value) => {
                setCartno(value.length)
                setCartitems(value)
                value.forEach((value, index, array) => {
                    prices += parseFloat((value.price) * value.qty)
                })
                setTotalbal(prices)
            }).catch((err) => { console.log(err) })
        GetADoc("accounts", account.email).then(v => {
            localStorage.setItem('account', JSON.stringify(v.data()))
            setInfo(v.data())
        }).catch(err => { })
    }
    try {
        return (
            <nav id='nav-bar' className="bg-black sticky top-0 z-50 container-fluid flex justify-between py-4 px-4 items-center w-full lg:w-4/6 mx-auto ">
                <img className="lg:hidden w-8 cursor-pointer" src={menu} alt="menu" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" />

                <NavLink to='/'><h1 className="text-lg font-extrabold cursor-pointer text-white" onClick={() => {

                }}>Picolocal</h1></NavLink>

                {!searchItem ? (<div className="hidden lg:flex gap-3">
                    <NavLink className="py-2 px-4 hover:font-bold text-gray-200" style={({ isActive }) => ({ fontWeight: isActive ? "900" : "white" })} to="/">Home</NavLink>
                    <NavLink className="py-2 px-4 hover:font-bold text-gray-200" style={({ isActive }) => ({ fontWeight: isActive ? "900" : "white" })} to="/laptopcomputers">Laptops</NavLink>
                    <NavLink className="py-2 px-4 hover:font-bold text-gray-200" style={({ isActive }) => ({ fontWeight: isActive ? "900" : "white" })} to="/phones&amp;Tablets">Phones&amp;Tablets</NavLink>
                    <NavLink className="py-2 px-4 hover:font-bold text-gray-200" style={({ isActive }) => ({ fontWeight: isActive ? "900" : "white" })} to="/desktopcomputerss">Desktops</NavLink>
                    <NavLink className="py-2 px-4 hover:font-bold text-gray-200" style={({ isActive }) => ({ fontWeight: isActive ? "900" : "white" })} to="/smartatches">Watches</NavLink>
                    {/* <NavLink className="py-2 px-4 hover:font-bold" style={({ isActive }) => ({ fontWeight: isActive ? "900" : "black" })} to="/earpodsandearbuds">Ear Pods</NavLink>
                 <NavLink className="py-2 px-4 hover:font-bold" style={({ isActive }) => ({ fontWeight: isActive ? "900" : "black" })} to="/others">Others</NavLink>
*/}
                </div>) :
                    (<div className="flex bg-slate-100 w-4/6 px-4 rounded-lg">

                        <img className="w-6 hover:text-red-800 cursor-pointer transition duration-150 ease-in-out" src={close} alt="search" onClick={() => setSearchItem(false)} />
                        <input className='py-2 px-2 w-full bg-slate-100 border-none outline-none transition duration-150 ease-in-out' onKeyPress={(e) => keypress(e)} type="text" value={searchitem} onChange={(e) => setSearchitem(e.target.value)} ref={searchRef} placeholder="Search Here... and press enter  (product name, brand or category)" />
                        <img className="w-6 cursor-pointer" src={searchb} color="#000000" alt="search"
                            onClick={() => {
                                searchAction(searchRef)
                            }
                            } />
                    </div>)}

                <div className="flex gap-2 relative">
                    {!searchItem && <img className="w-10 cursor-pointer hidden lg:block hover:bg-slate-500 hover:rounded-full p-2" src={search} alt="search" onClick={() => setSearchItem(true)} />}

                    <div className="dropdown relative">
                        <div className="dropdown-toggle transition duration-150 ease-in-out" id="user_menu" data-bs-toggle="dropdown" aria-expanded="false">
                            <img className="w-10 cursor-pointer hover:bg-slate-500 hover:rounded-full p-2" src={user} alt="user" ref={userbtn} />
                        </div>
                        {!login && (
                            <div className="align-self-center right-0 left-0 w-max dropdown-menu absolute top-5 hidden rounded-xl border-none p-4 bg-white shadow-xl" aria-labelledby='user_menu'>
                                <NavLink to='/signintoaccount'> <button className="bg-black py-2 px-8 hover:shadow-lg text-white rounded-full">Sign in</button>
                                </NavLink> </div>
                        )}
                        {login && (<div className="align-self-center right-0 left-0 w-max dropdown-menu absolute top-5 hidden rounded-xl border-none p-4 bg-white shadow-xl" aria-labelledby='user_menu'>
                            <div className="flex flex-col w-72">
                                <div className="flex mt-2 items-center gap-4">
                                    <img src={account.avatar} alt="like" className="h-12 bg-gray-400 rounded-full p-1" />
                                    <div className="flex flex-col">
                                        <h1 className="font-bold text-md">{account.name}</h1>
                                        <p className="text-xs">{account.address}</p>
                                    </div>
                                </div>
                                <div className="border-1  my-5"></div>
                                <div className="flex flex-col">
                                    <div className="flex px-2 justify-between items-center my-2">
                                        <h1 className="font-bold text-md"> {Mformatter(account.balance)}</h1>
                                        <button className="font-bold border-1 border-black px-2 rounded-lg" onClick={() => PaymentDialog(navigate)}>Top up</button>
                                    </div>
                                    <NavLink to='/myaccountpage'><div className="text-gray-500 p-2 hover:bg-gray-200 cursor-pointer text-md w-60 flex gap-4 items-center rounded-xl"><FaUser /> <p>My Account</p></div></NavLink>
                                    <NavLink to='/myaccountpage#my-order'><div className="text-gray-500 p-2 hover:bg-gray-200 cursor-pointer text-md w-60 flex gap-4 items-center rounded-xl"><FaShoppingBasket /><p>My Order</p></div></NavLink>
                                    <NavLink to='/myaccountpage#wish-list'><div className="text-gray-500 p-2 hover:bg-gray-200 cursor-pointer text-md w-60 flex gap-4 items-center rounded-xl"><FaHeart /><p>Wishlist</p></div></NavLink>
                                    <div className="border-1 border-gray-200 my-5"></div>
                                    <div className="text-gray-500 p-2 hover:bg-gray-200 cursor-pointer text-md w-60 flex gap-4 items-center rounded-xl"><FaQuestion /><p className="">Help</p></div>
                                    <div className="text-gray-500 p-2 hover:bg-gray-200 cursor-pointer text-md w-60 flex gap-4 items-center rounded-xl"
                                        onClick={() => Logout(navigate, setLogin)} ><FaSignOutAlt /><p>Log out</p></div>
                                </div>
                            </div>
                        </div>)}
                    </div>

                    <div className="w-10 cursor-pointer hover:bg-slate-500 hover:rounded-full p-2 dropdown relative" ref={cartbtn}>
                        <div className="dropdown-toggle transition duration-150 ease-in-out" id="cart_menu" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={cart} alt="cart" />
                            {cartno > 0 && <div className="bg-white flex justify-center h-4 w-4 absolute rounded-full top-0 right-0">
                                <p className="text-xs font-bold text-center  text-black">{cartno}</p>
                            </div>}
                        </div>

                        {!login && <div className="w-max mt-20 dropdown-menu absolute hidden rounded-xl border-none p-4 bg-white shadow-lg" aria-labelledby='cart_menu'>
                            <NavLink to="/signintoaccount"><button className="bg-black py-2 px-8 hover:shadow-lg text-white rounded-full">Sign in</button>
                            </NavLink>
                        </div>}
                        {login && (<div className="w-max  mt-20 dropdown-menu absolute hidden h-max rounded-xl border-none p-4 bg-white shadow-lg" aria-labelledby='cart_menu'>
                            <div className="flex flex-col p-2 overflow-y-scroll">
                                <h1 className="font-bold text-lg my-6">Shopping cart</h1>
                                {MiniCart(cartitems).map((value, index, array) => (
                                    <div className="w-96 md:w-full flex gap-4 mb-4 justify-between border-b-1 max-h-96 border-b-gray-50 pb-2" key={value.uid}>
                                        <img src={value.img} alt="heart" className="w-28 p-2 rounded-md bg-gray-200" />
                                        <div className="flex flex-col justify-between">
                                            <div>
                                                <p className="font-bold text-md truncate w-40 md:w-80">{value.displayname}</p>
                                                <p className="text-sm line-clamp-2 w-40 md:w-80">{value.short}</p>
                                            </div>
                                            <p className="text-sm">Quantity: {cartitems[index].qty}</p>
                                        </div>
                                        <div className="ml-5 w-full flex flex-col justify-between">
                                            <button className="border-2 border-green-600 px-2 text-green-600 rounded-lg">{Mformatter(PriceD(value) * cartitems[index].qty)}</button>
                                            <p className="text-sm font-bold text-blue-600 text-end"
                                                onClick={() => AlertDialog({
                                                    title: 'Remove Item',
                                                    msg: "Do you wish to delete this item from your cart?",
                                                    conf: true,
                                                    nbtn: "Cancel",
                                                    ybtn: 'Remove',
                                                    action: () => {
                                                        DeleteADoc(cartitems[index].id, "cart")
                                                            .then((v) => {
                                                                close();
                                                                AlertDialog({
                                                                    title: "Success",
                                                                    msg: "Your item has been successfully deleted from your cart ",
                                                                    nbtn: "Close",
                                                                    conf: false,

                                                                })
                                                            })

                                                    }
                                                })}
                                            >Remove</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {MiniCart(cartitems).length > 0 ? (<div className="bg-slate-50 p-2 w- rounded-br-md">
                                <div className="flex justify-between items-center">
                                    <h1 className="font-bold text-md">Subtotal</h1>
                                    <h1 className="font-extrabold text-xl">{Mformatter(totalbal)}</h1>
                                </div>
                                <p className="text-sm text-gray-400">Shipping fee and taxes calculated at checkout.</p>
                                <div className="flex justify-between mt-10 py-4">
                                    <Link to="/cartitems"><button className="border-1 border-gray-600 font-bold py-2 px-8 hover:shadow-lg rounded-full">View Full Cart</button></Link>
                                    <Link to="/checkoutpage"><button className="bg-black py-2 px-8 hover:shadow-lg text-white rounded-full">Check out</button></Link>
                                </div>
                            </div>) : (<div className="flex flex-col items-center justify-center px-6">
                                <img src={emptycart} alt="emptycart" className="h-48" />
                                <p className="font-bold mx-6 mb-4">Your cart is Empty</p>
                            </div>)}
                        </div>)}
                    </div>
                </div>

                {/* Sidenav */}
                <div className="offcanvas offcanvas-start fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 left-0 border-none w-96" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                    <div className="offcanvas-header flex items-center justify-between p-4">
                        <h5 className="ml-4 offcanvas-title mb-0 leading-normal font-semibold" id="offcanvasExampleLabel">Picolocal</h5>
                        <button type="button" className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="flex flex-col px-6">
                        <p className="text-sm my-4">Welcome To Picolocal <br></br> Picolocal is a Professional ecommerce platform which sell range of electronic devices with reasonable prices.</p>

                        {/*  <div className="flex gap-2 my-4">
                            <img className="lg:hidden w-8 cursor-pointer" src={telegram} alt="telegram" />
                            <img className="lg:hidden w-8 cursor-pointer" src={facebook} alt="facebook" />
                            <img className="lg:hidden w-8 cursor-pointer" src={twitter} alt="twitter" />
                            <img className="lg:hidden w-8 cursor-pointer" src={instagram} alt="instagram" />
                        </div> */}

                        <div className="flex bg-slate-100 w-full px-4 rounded-2xl justify-between">
                            <input className='py-2 px-2  bg-slate-100 border-none outline-none' onKeyPress={(e) => keypress(e)} value={searchitem} onChange={(e) => setSearchitem(e.target.value)} ref={msearchRef} type="text" name="" id="" placeholder="Search Here... (product name, brand or category)" />
                            <img className="w-6 cursor-pointer" src={searchb} alt="search" onClick={() => {
                                searchAction(msearchRef)
                            }} />
                        </div>
                        <div className="flex flex-col mt-10">
                            <NavLink className="py-2 px-4 hover:font-bold" style={({ isActive }) => ({ fontWeight: isActive ? "900" : "black" })} to="/">Home</NavLink>
                            <NavLink className="py-2 px-4 hover:font-bold" style={({ isActive }) => ({ fontWeight: isActive ? "900" : "black" })} to="/laptopcomputers">Laptops</NavLink>
                            <NavLink className="py-2 px-4 hover:font-bold" style={({ isActive }) => ({ fontWeight: isActive ? "900" : "black" })} to="/phones&amp;Tablets">Phones&amp;Tablets</NavLink>
                            <NavLink className="py-2 px-4 hover:font-bold" style={({ isActive }) => ({ fontWeight: isActive ? "900" : "black" })} to="/desktopcomputerss">Desktops</NavLink>
                            <NavLink className="py-2 px-4 hover:font-bold" style={({ isActive }) => ({ fontWeight: isActive ? "900" : "black" })} to="/smartatches">Watches</NavLink>
                            <NavLink className="py-2 px-4 hover:font-bold" style={({ isActive }) => ({ fontWeight: isActive ? "900" : "black" })} to="/earpodsandearbuds">Ear Pods</NavLink>
                            <NavLink className="py-2 px-4 hover:font-bold" style={({ isActive }) => ({ fontWeight: isActive ? "900" : "black" })} to="/others">Others</NavLink>
                        </div>
                    </div>
                </div>
            </nav>
        )
    } catch (err) { }
}

export const Carousel = () => {
    return (

        <div className="">
            <div id="carouselExampleCrossfade" className="flex md:hidden carousel slide carousel-fade relative w-full" data-bs-ride="carousel"        >
                <div className="flex carousel-indicators absolute right-0 bottom-0 left-0 justify-center p-0 mb-4">
                    {CaroucelImgsSm().map((value, index, array) => (
                        <button key={index} type="button" data-bs-target="#carouselExampleCrossfade" data-bs-slide-to={`${index}`} className={index === 0 ? "active" : ""} aria-current={index === 0 ? "true" : ""} aria-label={`Slide ${index}`}></button>
                    ))}
                </div>
                <div className="flex carousel-inner relative w-full overflow-hidden" style={{ background: "#000000" }}>
                    {/* {CaroucelData().map((value, index, array) => (
                    <div className={`flex carousel-item ${index === 0 ? "active" : ""} float- justify-center w-full`} key={index}>
                        <div className="flex flex-col lg:flex-row items-center lg:justify-between lg:w-4/6 gap-4 mx-auto">
                            <div className=" w-full lg:w-1/3 flex flex-col gap-4 z-40">
                                <p className="font-normal line-clamp-5">{value.short}</p>
                                <h1 className="font-bold md:font-extrabold leading-light text-4xl md:text-6xl line-clamp-2">{value.name}</h1>
                                <button className='bg-slate-900 rounded-full py-2 px-3 flex justify-center w-28  mt-5 text-white text-center'>
                                    See More
                                </button>
                            </div>
                            <div className="flex w-full lg:w-2/3">
                                <img className='object-contain block w-full -mt-48 md:-mt-15 lg:my-auto' style={{ height: "40rem", width: "30rem" }} src={value.img} alt={value.name} />
                            </div>
                        </div>
                    </div>
                ))}  */}


                    {CaroucelImgsSm().map((value, index, array) => (
                        <div className={`flex carousel-item ${index === 0 ? "active" : ""} justify-center w-full`} key={index}>
                            <img
                                src={value.img}
                                className="w-full md:w-4/6 mx-auto justify-center"
                                alt={"Picolocal online electronic devices store landing " + index}
                            />
                        </div>
                    ))}

                </div>
                <button
                    className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                    type="button"
                    data-bs-target="#carouselExampleCrossfade"
                    data-bs-slide="prev"
                >
                    <span className="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button
                    className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                    type="button"
                    data-bs-target="#carouselExampleCrossfade"
                    data-bs-slide="next"
                >
                    <span className="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div id="carouselExampleCrossfade2" className="hidden md:flex carousel slide carousel-fade relative w-full" data-bs-ride="carousel"        >
                <div className="flex carousel-indicators absolute right-0 bottom-0 left-0 justify-center p-0 mb-4">
                    {CaroucelImgs().map((value, index, array) => (
                        <button key={index} type="button" data-bs-target="#carouselExampleCrossfade2" data-bs-slide-to={`${index}`} className={index === 0 ? "active" : ""} aria-current={index === 0 ? "true" : ""} aria-label={`Slide ${index}`}></button>
                    ))}
                </div>
                <div className="flex carousel-inner relative w-full overflow-hidden" style={{ background: "#000000" }}>
                    {/* {CaroucelData().map((value, index, array) => (
                    <div className={`flex carousel-item ${index === 0 ? "active" : ""} float- justify-center w-full`} key={index}>
                        <div className="flex flex-col lg:flex-row items-center lg:justify-between lg:w-4/6 gap-4 mx-auto">
                            <div className=" w-full lg:w-1/3 flex flex-col gap-4 z-40">
                                <p className="font-normal line-clamp-5">{value.short}</p>
                                <h1 className="font-bold md:font-extrabold leading-light text-4xl md:text-6xl line-clamp-2">{value.name}</h1>
                                <button className='bg-slate-900 rounded-full py-2 px-3 flex justify-center w-28  mt-5 text-white text-center'>
                                    See More
                                </button>
                            </div>
                            <div className="flex w-full lg:w-2/3">
                                <img className='object-contain block w-full -mt-48 md:-mt-15 lg:my-auto' style={{ height: "40rem", width: "30rem" }} src={value.img} alt={value.name} />
                            </div>
                        </div>
                    </div>
                ))}  */}


                    {CaroucelImgs().map((value, index, array) => (
                        <div className={`flex carousel-item ${index === 0 ? "active" : ""} justify-center w-full`} key={index}>
                            <img
                                src={value.img}
                                className="w-full md:w-4/6 mx-auto justify-center"
                                alt={"Picolocal online electronic devices store landing " + index}
                            />
                        </div>
                    ))}




                </div>
                <button
                    className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                    type="button"
                    data-bs-target="#carouselExampleCrossfade2"
                    data-bs-slide="prev"
                >
                    <span className="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button
                    className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                    type="button"
                    data-bs-target="#carouselExampleCrossfade2"
                    data-bs-slide="next"
                >
                    <span className="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>

    )
}

export const BlackFriday = () => {
    return (
        <div className="flex w-full flex-col ">
            <div className="flex w-full mx-auto relative">
                {/* <p className="text-2xl font-bold text-white absolute z-10">Days: { Counter() } more</p> */}
                <img className="mx-auto" src={bf} alt="Piclolocal black friday deals" />
            </div>
        </div>
    )
}

export const Discover = () => {
    const navigate = useNavigate()
    return (
        <div className="flex flex-col mt-20 p-4">
            <div className="flex w-full lg:w-4/6 mx-auto">
                <h1 className='text-2xl lg:text-4xl font-bold'>Discover more. <span className="text-gray-400">Good products are waiting for you</span></h1>
                <div className="flex gap-3">
                    <div className="rounded-full p-2"> </div>
                    <div className="rounded-full p-2"> </div>
                </div>
            </div>
            <div className="grid md:grid-cols-2 items-center gap-4 mt-10 mx-auto lg:w-4/6">
                {DiscoverData().map((value, index, array) => (
                    <div className="flex p-4 h-64 gap-2 rounded-lg w-full" key={index} style={{ background: value.col }}>
                        <div className="flex flex-col place-items-start justify-between w-3/4">
                            <div className="flex flex-col">
                                <p className="text-sm">{value.short}</p>
                                <h1 className="text-lg font-bold mt-2">{value.desc}</h1>
                                <p className="text-xs text-gray-600 my-4">{value.page}</p>
                            </div>
                            <button className="rounded-full px-4 py-2 bg-white hover:shadow-lg" onClick={() => value.action(navigate)}>{value.actiontxt}</button>
                        </div>
                        <div className="flex w-2/4 justify-center ">
                            <img className='object-contain h-auto' src={value.img} alt="" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const Arrivals = () => {
    const [object, setObject] = useState(...Item("qybe8sKWkxV2lbXQ3G25f1E82RnmM2"));
    const [qty, setQty] = useState(1);
    var account = JSON.parse(localStorage.getItem('account'))
    return (
        <div className="flex flex-col mt-20 p-4">
            <div className="flex w-full lg:w-4/6 mx-auto">
                <h1 className='text-2xl lg:text-4xl font-bold'>New Arrivals. <span className="text-gray-400">Electronic devices you have waited for so long</span></h1>
                <div className="flex gap-3">
                    <div className="rounded-full p-2"> </div>
                    <div className="rounded-full p-2"> </div>
                </div>
            </div>
            <div className="w-full flex-col md:grid md:grid-cols-2 lg:grid lg:grid-cols-4 items-center gap-4 mt-10 mx-auto lg:w-4/6">
                {ArrivalData().map((value, index, array) => (
                    <div className="flex flex-col p-4 gap-2 rounded-lg w-full bg-slate-50" key={index}>
                        <div className="flex justify-between w-full">
                            <p className="p-2 rounded-lg bg-white text-sm">{value.brand}</p>
                            <img className='h-10 p-2 rounded-full bg-white' src={likeoff} alt="heart" />
                        </div>
                        <div className="flex p-2 justify-center relative">
                            <img className="object-contain h-48 w-44" src={value.img} alt={value.title} />
                            {value.discount > 0 ? <div className="flex flex-col justify-center items-center rounded-full bg-black px-4 py-2 absolute right-2 -top-5">
                                <h1 className='text-white text-xs font-bold'>was {Mformatter(value.price / Quotient)}</h1>
                            </div> : null}
                        </div>
                        <div className="flex justify-center gap-4 my-2">
                            <button className="rounded-2xl py-2 px-4 text-xs hover:shadow-md cursor:hover hover:translate-y-1 delay-100 bg-black text-white"
                                onClick={() => {
                                    /*  AlertDialog({
                                         title: "Add To Cart",
                                         msg: "Do you want to add " + value.displayname + " to your cart?",
                                         conf: true,
                                         nbtn: "Cancel",
                                         ybtn: "Yes, Continue",
                                         action: () => {
                                             Progress('Adding ' + value.displayname + " to cart")
                                             var item = {
                                                 id: Date.now().toString(),
                                                 email: account.email,
                                                 qty: qty,
                                                 uid: value.uid,
                                                 price: (((((object.price  / Quotient) - ((object.price  / Quotient) * (object.discount / 100))) * qty).toFixed(2)))
                                             }
                                             
 
                                              
                                             setQty(1)
                                             SetADoc('cart', item.id, item)
                                                 .then((res) => {
                                                     AlertDialog({
                                                         title: "Cart Success",
                                                         msg: "Item has been added to your cart successflly",
                                                         conf: false,
                                                         nbtn: "Close",
                                                         
                                                     })
                                                 })
                                         }
                                     }) */
                                    AddToCartAction(value, account, qty, setQty)
                                }}
                            >Add to cart</button>
                            <button className="rounded-2xl py-2 px-4 text-xs hover:shadow-md cursor:hover hover:translate-y-1 delay-100 border-1 border-black"
                                data-bs-toggle="modal" data-bs-target="#quickview"
                                onClick={() => setObject(value)}
                            >Quick view</button>
                            <QuickView object={object} />

                        </div>
                        {/* 
                        <div className="flex gap-2">
                            <div className="rounded-full h-4 w-4 p-1 bg-blue-300" ></div>
                            <div className="rounded-full h-4 w-4 p-1 bg-red-300" ></div>
                            <div className="rounded-full h-4 w-4 p-1 bg-green-300" ></div>
                            <div className="rounded-full h-4 w-4 p-1 bg-orange-300" ></div>
                        </div> */}
                        <div className="bg-white rounded-lg p-2">
                            <div className="flex flex-col gap-2 mt-4">
                                <h1 className="text-md font-bold truncate">{value.displayname}</h1>
                                <h1 className="text-sm font-normal truncate">{value.short}</h1>
                            </div>
                            <div className="flex justify-between mt-5">
                                <button className="border-2 border-green-600 rounded-lg bg-white px-2 text-green text-lg font-bold text-green-600 hover:bg-green-200">{PriceDistribution(value)}</button>
                                <div className="flex gap-2 items-center">
                                    <img src={rate} alt="rate" className="h-4" />
                                    <p className="text-sm">{Rating()[0].rate} ({Rating()[0].review} reviews)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export const Bestseller = () => {
    const navigate = useNavigate()
    var account = JSON.parse(localStorage.getItem('account'))
    const [qty, setQty] = useState(1);
    return (
        <div className="flex flex-col mt-20 p-4">
            <div className="flex w-full lg:w-4/6 mx-auto">
                <h1 className='text-2xl lg:text-4xl font-bold'>Best Seller. <span className="text-gray-400">See the current trend of most purchased electronics</span></h1>
                <div className="flex gap-3">
                    <div className="rounded-full p-2"> </div>
                    <div className="rounded-full p-2"> </div>
                </div>
            </div>
            <div className="w-full flex-col md:grid md:grid-cols-2 lg:grid lg:grid-cols-4 items-center gap-4 mt-10 mx-auto lg:w-4/6">
                {BestsellerData().map((value, index, array) => (
                    <div className="flex flex-col p-4 gap-2 rounded-lg w-full border-1 border-gray-200" key={index}>
                        <div className="flex justify-between w-full">
                            <p className="p-2 rounded-lg bg-white text-sm">{value.brand}</p>
                            <img className='h-10 p-2 rounded-full bg-white' src={likeoff} alt="heart" />
                        </div>
                        <div className="flex p-2 justify-center relative">
                            <img className="object-contain h-48 w-44" src={value.img} alt={value.brand} />

                        </div>
                        <div className="flex justify-center gap-4 my-2">
                            <button className="rounded-2xl py-2 px-4 text-xs hover:shadow-md cursor:hover hover:translate-y-1 delay-100 bg-black text-white"
                                onClick={() => AddToCartAction(value, account, qty, setQty)}
                            >Add to cart</button>
                            <button className="rounded-2xl py-2 px-4 text-xs hover:shadow-md cursor:hover hover:translate-y-1 delay-100 border-1 border-black"
                                onClick={() => navigate('/fullspecification', { state: { obj: value } })}>View Specs</button>
                        </div>
                        <div className="flex gap-2">
                            {/* <div className="rounded-full h-4 w-4 p-1 bg-blue-300" ></div>
                            <div className="rounded-full h-4 w-4 p-1 bg-red-300" ></div>
                            <div className="rounded-full h-4 w-4 p-1 bg-green-300" ></div>
                            <div className="rounded-full h-4 w-4 p-1 bg-orange-300" ></div> */}
                        </div>
                        <div className="bg-white rounded-lg p-2 border-1 border-gray-200">
                            <div className="flex flex-col gap-2 mt-4">
                                <h1 className="text-md font-bold truncate">{value.displayname}</h1>
                                <h1 className="text-sm font-normal truncate">{value.short}</h1>
                            </div>
                            <div className="flex justify-between mt-5">
                                <button className="border-2 border-black rounded-lg bg-white px-2 text-green text-lg font-bold text-gray-600 hover:bg-gray-200">{PriceDistribution(value)}</button>
                                <div className="flex gap-2 items-center">
                                    <img src={rate} alt="rate" className="h-4" />
                                    <p className="text-sm">{Rating()[0].rate} ({Rating()[0].review} reviews)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const Divider = () => {
    return (
        <div className="w-full flex-col md:grid md:grid-cols-4 items-center my-20 mx-auto lg:w-4/6 border-b-1 border-gray-200"></div>
    )
}

export const Walktrhough = () => {
    return (
        <div className="flex w-full flex-col md:flex-row mx-auto lg:w-4/6 gap-4">
            {
                WalkthoughData().map((value, index, array) => (
                    <div className="flex flex-col gap-4 items-center text-center" key={index} >
                        <img src={value.img} alt={value.title} className="h-28 object-contain" />
                        <p className="px-3 py-1 rounded-2xl text-sm" style={{ background: value.bg, color: value.fg }}>Step {index + 1}</p>
                        <h1 className="font-bold">{value.title}</h1>
                        <p className="">{value.desc}</p>
                    </div>
                ))
            }
        </div>
    )
}

export const PaymentGateWay = () => {
    const navigate = useNavigate()
    return (
        <div className="flex flex-col md:flex-row justify-between mx-auto w-full lg:w-4/6 mt-20 rounded-2xl bg-slate-50 p-8 gap-8">
            <div className="flex flex-col gap-4 justify-center w-full md:w-2/4 order-2 md:order-1">
                <h1 className="font-bold text-2xl">Picolocal</h1>
                <h1 className="font-bold text-2xl md:text-4xl lg:text-5xl uppercase">Picolocal Accepts cryptocurrency</h1>
                <p className="text-lg  font-small">With Picolocal you will get more discounts &amp; save more than you can on other electronic stores.</p>
                <div className="flex gap-6 mt-4">
                    <button className="py-2 px-4 hover:shadow-lg cursor-pointer bg-black text-white font-bold rounded-full"
                        onClick={() => PaymentDialog(navigate)}
                    >Add Balance</button>
                    <button className="py-2 px-4 hover:shadow-lg cursor-pointer text-black font-medium rounded-full border-1 border-slate-200"
                        onClick={() => {
                            AlertDialog({
                                title: "Payment Gateways",
                                msg: "Picolocal has always provide simplest and ease of access of payments which can be done via Crypto (Bitcoin, Thether, Ethereum, Litecoin, etc...), Paypal, Credit/Debit.",
                                nbtn: "Close",
                                conf: false
                            })
                        }}>Discover More</button>
                </div>
            </div>
            <div className="w-full md:w-2/4 order-1">
                <img className='object-contain ' src="https://chisnghiax.com/ciseco/static/media/rightLargeImg.dd2356513f3941fd1981.png" alt="Cryptocurrency" />
            </div>
        </div>
    )
}

export const Categories = () => {
    const [indexno, setindexno] = useState(0);
    const [indexname, setindexname] = useState('phones');
    const navigate = useNavigate()

    return (
        <div className="flex flex-col items-center mx-auto w-full lg:w-5/6 mt-20 rounded-2xl bg-gray-200 p-8 gap-2 lg:p-40 lg:gap-8">
            <h1 className="font-bold text-4xl mb-5 lg:text-5xl">Sort Something Out</h1>
            <div className="flex gap-2 rounded-lg lg:rounded-full h-10 md:h-auto hover:h-auto mb-10 overflow-hidden px-4 py-2 w-full lg:w-auto shadow-lg bg-white">
                <ul className='grid md:grid-cols-6 gap-2 lg:flex lg:gap-4 cursor-pointer'>
                    <p className="md:hidden text-center justify-center">Tap or hover to select your brand</p>
                    {CategoriesData().map((value, index, array) => (
                        <div className={index === indexno ? "text-white items-center justify-center bg-black px-3 py-1 rounded-full flex" : "flex items-center hover:font-bold"} key={index} onClick={() => {
                            setindexname(value)
                            setindexno(index)
                        }}>{value}</div>
                    ))}
                </ul>

            </div>
            <div className="flex flex-col md:grid lg:grid-cols-3 md:grid-cols-2 gap-4 w-full">

                {ItemsData(indexname).map((value, index, array) => (

                    <div className="flex flex-col p-6 rounded-xl bg-white  relative" key={index}>
                        <div className="flex justify-between items-center">
                            <img className='h-16 rounded-full p-2 bg-slate-100' src={value.logo} alt={value.brand} />
                            <p className="text-sm">{value.size} total products</p>
                        </div>

                        <div className="flex justify-between mt-6 h-40">
                            <div className="flex justify-between flex-col">
                                <div className="flex flex-col">
                                    <h1 className="font-bold text-4xl">{value.brand} </h1><span className="text-sm">{value.category}</span>
                                </div>
                                <p className="text-black text-sm hover:text-blue-400 cursor-pointer"
                                    onClick={() => {
                                        console.log(value.brand, value.category)
                                        navigate('/searchnameandcatitempage', { state: { query: { brand: value.brand, category: value.category } } })
                                    }
                                    }
                                >See this Collection</p>
                            </div>
                        </div>
                        <img src={'https://chisnghiax.com/ciseco/static/media/explore1.775937cb2511726e48c3fc70025fab60.svg'} alt={value.title} className="object-contain w-48 absolute bottom-0 right-0 opacity-" />
                    </div>
                ))}
            </div>
        </div>
    )
}

export const Footer = () => {
    const navigate = useNavigate();

    return (
        <div className="bg-gray-50 pt-4">
            <footer className='w-ful grid grid-cols-2 mt-10 lflex flex-wrap md:grid md:grid-cols-2 lg:flex lg:flex-row gap-4 lg:gap-8 justify-between lg:w-4/6 mx-auto px-4 lg:px-0'>
                {FootElements()[0].map((value, index, array) => (
                    <div className="flex gap-4 flex-wrap" key={index}>
                        <div className="">
                            <h1 className="font-bold">{value.title}</h1>
                            {value.data.map((v, i, a) => (
                                <div className="my-5" key={i}>
                                    <ul className="">
                                        {v.cat.map((va, i, a) => (
                                            <NavLink className="flex flex-col py-1 px-1 hover:font-bold" to="/" key={i}>{va}</NavLink>

                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                {FootElements()[1].map((value, index, array) => (
                    <div className="flex gap-4 flex-wrap w-full md:w-3/5 lg:w-96" key={index}>
                        <div className="">
                            <h1 className="font-bold">{value.title}</h1>
                            {value.data.map((v, i, a) => (
                                <div className="flex flex-col gap-4 my-5" key={i}>
                                    <p className="font-bold">Address: <span className="font-normal">{v.address}</span></p>
                                    <div className="flex gap-2">
                                        <p className="font-bold">Phone: <span className="font-normal">+{Formatter(v.phone.a)} || +{Formatter(v.phone.b)}</span></p>
                                    </div>
                                    <div className="flex gap-2">
                                        <p className="font-bold">Email address: <span className="font-normal">{v.email.a} || {v.email.b}</span></p>
                                    </div>
                                    <hr className="my-5" />
                                    {/*  <p className="font-bold text-center">Follow our Media handles </p>
                                    <div className="flex justify-center gap-2 ">
                                        <FaFacebook className="cursor-pointer" />
                                        <FaInstagram className="cursor-pointer" />
                                        <FaYoutube className="cursor-pointer" />
                                        <FaTwitter className="cursor-pointer" />
                                        <FaTelegram className="cursor-pointer" />
                                    </div> */}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                {FootElements()[2].map((value, index, array) => (
                    <div className="flex gap-4 flex-wrap" key={index}>
                        <div className="">
                            <h1 className="font-bold">{value.title}</h1>
                            <div className="flex flex-col gap-4 my-5">

                                <Link to="/licensetermsandconditionsagreement"><p className="cursor-pointer hover:underline">Terms&amp;Conditions</p></Link>
                                <Link to="/privacytermsandconditions"><p className="cursor-pointer hover:underline">Privacy Policy</p></Link>

                            </div>
                        </div>
                    </div>
                ))}
                {FootElements()[3].map((value, index, array) => (
                    <div className="flex gap-4 flex-wrap" key={index}>
                        <div className="">
                            <h1 className="font-bold">{value.title}</h1>
                            <div className="flex flex-col gap-4 my-5">
                                {value.data.map((v, i, a) => (
                                    <div className="flex gap-2" key={index}>
                                        <ul className="">
                                            {v.crypto.map((v, i, a) => (
                                                <p className="flex justify-end flex-col py-1 px-1 hover:font-bold cursor-pointer" onClick={() => {
                                                    if (i === 0) { PaymentDialog(navigate) }
                                                    else {
                                                        AlertDialog({
                                                            title: "Payment Warning",
                                                            msg: "Please this payment gateway would be coming soon on Picolocal but untill then, please use our Cryptocurrency payment gateway to checkout your orders by paying with either Bitcoin (BTC), Tether (USDT), Ethereum (ETH)",
                                                            nbtn: "Close",
                                                            conf: false
                                                        })
                                                    }
                                                }
                                                } key={i}>{v}</p>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))
                }
                {
                    FootElements()[4].map((value, index, array) => (
                        <div className="hidden w-full lg:flex gap-4 flex-wrap" key={index}>
                            <div className="">
                                <h1 className="font-bold">{value.title}</h1>
                                <div className="flex flex-col gap-4 my-5">
                                    {value.data.map((v, i, a) => (
                                        <p className="w-full lg: 3/5" key={i} >{v.content}</p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))
                }

            </footer >
            {
                FootElements()[4].map((value, index, array) => (
                    <div className="lg:hidden px-4 w-full flex gap-4 flex-wrap" key={index}>
                        <div className="">
                            <h1 className="font-bold">{value.title}</h1>
                            <div className="flex flex-col gap-4 my-5">
                                {value.data.map((v, i, a) => (
                                    <p className="w-full lg: 3/5" key={i}>{v.content}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                ))
            }
            <div className="p-4 bg-gray-800 flex justify-center">
                <p className="text-white text-bold">Picolocal &copy;2019 - {new Date().getFullYear()}</p>
            </div>
        </div >
    )
}

export const Chosen = () => {
    return (
        <div className="flex flex-col items-center mx-auto w-full lg:w-5/6 mt-20 rounded-2xl bg-gray-100 p-8 gap-2 lg:p-40 lg:gap-8">
            <div className="flex flex-col gap-6 md:grid md:grid-cols-4 lg:grid-cols-4">
                
                {ChosenData().map((value, index, array) => (<div className="flex gap-8 flex-col border-1 p-4 rounded-lg " key={index}>
                    <div className="flex justify-center rounded-lg p-4 bg-white">
                        <img className="object-contain w-58 h-40 " src={value.o} alt={value.t} />
                    </div>
                    <div className="flex gap-4">
                        <div className="">
                            <img className="object-contain w-20 h-16 rounded-lg p-2 bg-white" src={value.r} alt={value.t} />
                        </div>
                        <div className="">
                            <img className="object-contain w-20 h-16 rounded-lg p-2 bg-white" src={value.m} alt={value.t} />
                        </div>
                        <div className="">
                            <img className="object-contain w-20 h-16 rounded-lg p-2 bg-white" src={value.l} alt={value.t} />
                        </div>
                    </div>
                </div>))}
            </div>
        </div>
    )
}
