import React from 'react'
import {
    Arrivals,
    Carousel,
    Categories,
    Discover,
    Divider,
    Navbar,
    PaymentGateWay,
    Walktrhough,
    Bestseller,
    Footer,
    Chosen,
    BlackFriday
} from '../components/home/component';

const HomePage = () => {
    document.title = "Picolocal | Home of all affordable computing electronic devices"
    return (
        <div className='flex flex-col w-full'>
            <div className="bg-black w-full sticky top-0 z-50">
                <Navbar />
            </div>
            <Carousel />
            <Discover />
            <BlackFriday />
            <Arrivals />
            <Divider />
            <Walktrhough />
            <PaymentGateWay />
            <Categories />
            <Bestseller />
            <Chosen />
            <Divider />
            <Footer />
        </div>
    )
}

export default HomePage