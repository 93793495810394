import React from 'react'
import { useLayoutEffect, useEffect, useState } from 'react'
import { GetADoc, GetADocWhere, SetADoc } from '../../config/db'
import '../../index.css'
import { where } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import { FullCart } from '../../data/data_items'
import { Mformatter } from '../../config/function';


const Tab3 = ({ list }) => {

    const [cartitems, setCartitems] = useState([])
    const navigate = useNavigate();
    const [totalbal, setTotalbal] = useState(0)
    const [cartno, setCartno] = useState(0);
    const [qty, setQty] = useState(1);
    var account = JSON.parse(localStorage.getItem('account'))

    const [email, setEmail] = useState("");




    useLayoutEffect(() => {
        GetADocWhere('orders', where('email', '==', list.email))
            .then((value) => {
                setCartno(value.length)
                setCartitems(value)
            }).catch((err) => { console.log(err) })
        setEmail(list.email)
    }, [list.code, list.email, list.phone])
    return (
        <div >
            <h1 className="font-bold text-3xl"> My order history</h1>
            <div className="w-full flex flex-col">
                {cartitems.length === 0 && <p>No orders yet</p>}
                {cartitems.reverse().map((val, index, array) => (
                    <div className="flex flex-col lg:flex-row gap-16 mt-20 border-t-1 border-t-gray-200" key={val.orderno}>
                        <div className="w-full pb-5 rounded-lg border-1 border-gray-400 ">
                            <div className="flex items-center justify-between bg-gray-200 rounded-tr-lg rounded-tl-lg p-5 border-b-1 border-b-gray-400">
                                <div className="">
                                    <h1 className="font-bold text-xl">#{val.orderno}</h1>
                                    <div className="flex gap-4 mt-2">
                                        <h1 className="text-md">{val.date}</h1>
                                        <h1 className="text-md text-blue-500">Delivered</h1>
                                    </div>
                                </div>
                                <button className="px-6 py-2 rounded-full h-max bg-white hover:border-1 border-slate-500">{Mformatter(val.amount)}</button>
                            </div>
                            <div className=" px-5 w-full bg-white">
                                {FullCart(val.items).map((value, index, array) => (<div className="flex w-full  gap-4 mt-5 h-max" key={index}>
                                    <img src={value.img} alt="linkoff" className="object-contain h-32 w-28  p-2 rounded-lg" />

                                    <div className="flex flex-col w-full justify-between">
                                        <div className="flex flex-col w-full gap-1">
                                            <div className="flex w-full justify-between gap-4">
                                                <p className="font-bold text-md line-clamp-2">{value.displayname}</p>

                                                <div className="px-2 rounded-lg border-1 border-green-600 text-green-600 h-max">${Mformatter(((value.price / 10) - ((value.price / 10) * (value.discount / 100))).toFixed(2))}</div>
                                            </div>
                                            <div className="flex gap-4 items-center text-gray-400">
                                                <p className="text-sm line-clamp-2 w-5/6">{value.short}</p>
                                            </div>
                                        </div>

                                        <div className="flex justify-between">
                                            <p className="font-medium text-md">Qty: {val.items[index].qty}</p>
                                            <p className="text-blue-600 font-medium">Review</p>
                                        </div>
                                    </div>
                                </div>))}
                            </div>

                        </div>
                    </div>))}
            </div>
        </div>
    )
}

export default Tab3