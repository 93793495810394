
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import '../../react-confirm-alert.css';
import rate from '../../images/rate.svg'
import parse from 'html-react-parser'
import { SetADoc } from '../../config/db';
import { AlertDialog } from '../alert/alert';
import { Discount, Mformatter, PriceD, PriceDistribution , Quotient } from '../../config/function';
import { images } from '../../data/mdata';

export const Details = () => {

    var [qty, setQty] = useState(1);
    const { state } = useLocation();
    var account = JSON.parse(localStorage.getItem('account'))
    const object = state.obj
    const [amz, setAmz] = useState([])

    useEffect(() => {
        if (object.asin !== '') {
            const pimg = images.filter((value, index, array) => value.asin === object.asin)
            //console.log(pimg[0].imgs)
            setAmz(pimg[0].imgs ?? [])
        }


    }, [amz, object.asin])
    return (
        <div className="flex flex-col mx-auto lg:w-4/6 lg:flex-row relative rounded-xl mt-20 w-full gap-8 px-4">

            <div className="w-full lg:w-2/5">

                <div className="flex justify-start">
                    <img className="w-full object-cover lg:object-contain p-4 rounded-xl" src={object.img} alt={object.name} />
                </div>

                {object && (<div className="grid grid-cols-3 mr-6 mt-6 gap-2">
                    {object.imgs.map((value, index, array) => (
                        <img className="cursor-pointer hover:bg-slate-500" src={value.img} alt={value.name} key={index} />
                    ))}
                </div>)}
                {amz && (<div className="grid grid-cols-5 mr-6 mt-6 gap-2">
                    {amz.map((value, index, array) => (
                        <img className="h-18 w-16 cursor-pointer hover:bg-slate-500" src={value.img} alt={value.name} key={index} />
                    ))}
                </div>)}
            </div>
            <div className="w-full lg:w-3/5 mr-8">
                <h1 className="text-lg font-bold">{object.displayname}</h1>
                <h1 className="text-sm my-4 line-clamp-4">{object.short.replace('Jumia', 'Picolocal')}</h1>
                <div className="flex mt-6 gap-6 items-center">
                    <button className="border-2 border-green-600 rounded-lg h-max bg-white px-2 text-green text-lg font-bold text-green-600 hover:bg-green-200">{Mformatter(PriceD(object) * qty)}</button>
                    <div className="flex flex-col items-center">
                        <p className="font-bold text-black rounded-full bg-gray-200 w-max h-max py-1 px-3">-{Discount((object.price / Quotient), PriceD(object))} off</p>
                        <p className="font-sm font-bold line-through">{Mformatter(object.price / Quotient)}</p>
                    </div>
                    <div className="flex gap-2 items-center">
                        <img src={rate} alt="rate" className="h-4" />
                        <p className="text-sm">{object.rate} ({object.review} reviews)</p>
                    </div>
                </div>
                <div className="flex gap-8 mt-16 justify-between items-center h-max">
                    <div className="flex gap-4 items-center h-max">
                        <p className="p-2 rounded-full hover:bg-gray-50 h-8 w-8 border-1 border-gray-400 cursor-pointer text-center text-md font-medium flex items-center justify-center"
                            onClick={() => qty > 1 && setQty(--qty)}
                        >-</p>
                        <p className=" text-md font-medium flex justify-center">{qty}</p>
                        <p className="p-2 rounded-full hover:bg-gray-50 h-8 w-8 border-1 border-gray-400 cursor-pointer text-center text-md font-medium flex items-center justify-center"
                            onClick={() => qty < 10 && setQty(++qty)}
                        >+</p>
                    </div>
                    <button className="w-full px-6 py-4 rounded-full bg-black text-white font-extrabold text-lg"
                        onClick={() => {
                            AlertDialog({
                                title: "Add To Cart",
                                msg: "Do you want to add this item to your cart?",
                                conf: true,
                                nbtn: "Cancel",
                                ybtn: "Yes, Continue",
                                action: () => {

                                    var item = {
                                        id: Date.now().toString(),
                                        email: account.email,
                                        qty: qty,
                                        uid: object.uid,
                                        price: (((((object.price / Quotient) - ((object.price / Quotient) * (object.discount / 100))) * qty).toFixed(2)))
                                    }
                                    var lc = JSON.parse(localStorage.getItem('cartlocal'));
                                    /* lc.push(item)
                                    localStorage.setItem('cartlocal', JSON.stringify(lc)) */
                                    setQty(1)
                                    console.log(item)
                                    SetADoc('cart', item.id, item)
                                        .then((res) => {
                                            AlertDialog({
                                                title: "Success",
                                                msg: "Item " + object.displayname + " successfully added to your cart?",
                                                conf: false,
                                                nbtn: "Okay",
                                            })
                                        })
                                }
                            })
                        }}
                    >Add to cart</button>
                </div>
                <div className="flex flex-col mt-8">
                    <h1 className="font-bold">DESCRIPTION</h1>
                    {parse(object.desc.replace('Jumia', 'Picolocal'))}
                </div>
            </div>
        </div>
    )

}