

import React from 'react'
import { useLocation } from 'react-router-dom'
import { Account } from '../components/account/accountcomp'
import { Divider, Footer, Navbar } from '../components/home/component'

const AccountPage = () => {
   const location = useLocation()
   document.title = location.pathname.replace('/','').FirstUppercase();
    return (<div className='flex flex-col w-full'>
        <div className="bg-black w-full sticky top-0 z-50">
            <Navbar />
        </div>
            <Account />
            <Divider />
            <Footer />
    </div>
    )
}

export default AccountPage