

import React from 'react'
import { useLocation } from 'react-router-dom'
import { Details } from '../components/eproducts/details'
import { Footer, Navbar, Divider, Bestseller, } from '../components/home/component'
import { SearchQuery } from '../components/search/Search'


const SpecsPage = () => {
    const location = useLocation()
    document.title = location.pathname.replace('/', '').FirstUppercase();
    
    return (<div className='flex flex-col w-full'>
        <div className="bg-black w-full sticky top-0 z-50">
            <Navbar />
        </div>
            <Details />
            <Bestseller />
            <Divider />
            <Footer />
    </div>
    )
}

export default SpecsPage