import { AlertDialog, Progress } from "../components/alert/alert"
import { GetADoc, SetADoc, Signout } from "./db"
import { ProductData } from '../data/pdata'
import '../index.css'
import axios from 'axios';

export const CheckoutAddress = (value) => {
  AlertDialog({
    title: "Default " + value.addresstype + " Address",
    msg: "Please make changes to your " + value.addresstype + " address?",
    nbtn: "No",
    conf: true,
    ybtn: "Make default",
    action: () => {
      SetADoc(`active${value.addresstype.toLowerCase()}`, value.id, value)
        .then((v) => {
          AlertDialog({
            title: "Success",
            msg: "Your " + value.addresstype + " address with address name " + value.address + " has been set to default " + value.addresstype + " address",
            nbtn: "Close",
            conf: false,

          })
        }).catch((err) => console.log(err))
    }
  })

}
export const DiscoveryMsg = (title, message) => {
  AlertDialog({
    title: title,
    msg: message,
    nbtn: "Close",
    conf: false

  })

}

export const Logout = (nav, login) => {
  AlertDialog({
    title: "Confirm Logout",
    msg: "Do you really want to logout this account?",
    nbtn: "No",
    conf: true,
    ybtn: "Logout",
    action: () => {
      Signout()
        .then(async (v) => {
          login(false)
          localStorage.clear()
          console.log("state: ", localStorage.getItem('account'))
          nav("/home", { replace: true })
        })
    }
  })

}

export const Item = (uid) => {
  return ProductData().filter((value) => value.uid === uid)
}
export const Search = (name) => {
  return ProductData().filter((value) => value.displayname.toLowerCase().includes(name) || value.name.toLowerCase().includes(name) || value.brand.toLowerCase().includes(name) || value.category.toLowerCase().includes(name))
}
export const Search2 = (brand, category) => {
  return ProductData().filter((value) => value.brand.toLowerCase() === brand.toLowerCase() && value.category.toLowerCase() === category.toLowerCase())
}
export const Catitem = (cat) => {
  return ProductData().filter((value) => value.category.includes(cat))
}
export const Catitems = (bra, cat) => {
  return ProductData().filter((value) => value.brand.includes(bra) && value.category.includes(cat))
}


export const PaymentAPI = async (amt) => {
  const fetch = require('node-fetch');

  const url = 'https://api.commerce.coinbase.com/charges';
  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'X-CC-Version': process.env.REACT_APP_VERSION,
      'X-CC-Api-KEY': process.env.REACT_APP_PAY,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      local_price: { amount: amt, currency: 'USD' },
      metadata: { customer_id: 'skldfasdlfaf', customer_name: 'afdfadsf' },
      name: 'Picolocal',
      description: 'Topup your Picolocal account balance in able to make purchase',
      pricing_type: 'fixed_price'
    })
  };

  return await fetch(url, options)
  /*  .then(res => res.json())
   .then(json => console.log(json))
   .catch(err => console.error('error:' + err)); */
}
export const CheckPaymentAPI = async (code) => {
  const fetch = require('node-fetch');

  const url = 'https://api.commerce.coinbase.com/charges/' + code;
  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'X-CC-Version': process.env.REACT_APP_VERSION,
      'X-CC-Api-KEY': process.env.REACT_APP_PAY,
      'content-type': 'application/json'
    }
  };

  return await fetch(url, options)

}

export const Discount = (was, now) => {
  var nfw = (was - now)
  var nfwdw = nfw / was;
  return parseInt(nfwdw * 100) + "%"
}

export const Addtocart = (list) => {
  SetADoc('cart',)
}

export const AddToCartAction = (value, account, qty, setQty) => {
  AlertDialog({
    title: "Add To Cart",
    msg: "Do you want to add this item to your cart?",
    conf: true,
    nbtn: "Cancel",
    ybtn: "Yes, Continue",
    action: () => {

      var item = {
        id: Date.now().toString(),
        email: account.email,
        qty: qty,
        uid: value.uid,
        price: PriceD(value)
      }
      setQty(1)
      //console.log(item)
      SetADoc('cart', item.id, item)
        .then((res) => {
          AlertDialog({
            title: "Success",
            msg: "Item is successfully added to your cart?",
            conf: false,
            nbtn: "Okay",
          })
        })
    }
  })
}

export const Formatter = (number) => {
  try {

    return number.replace(/\D/g, '')
      .replace(/^(\d)/, '($1')
      .replace(/^(\(\d{3})(\d)/, '$1) $2')
      .replace(/(\d{4})(\d{1,5})/, '$1-$2')
      .replace(/(-\d{5})\d+?$/, '$1');
  } catch (error) {
  }
}
export const Mformatter = (number) => {
  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
}

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'FirstUppercase', {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

export const Quotient = 14.7;

export const PriceDistribution = (value) => {
  var price = parseFloat((value.price / Quotient).toFixed(2))
  return Mformatter((price) - ((price) * (value.discount / 80)))
}
export const PriceD = (value) => {
  var price = parseFloat((value.price / Quotient).toFixed(2))
  return parseFloat((price) - ((price) * (value.discount / 80)).toFixed(2))
}

export const Rating = () => {
  var a = (Math.random() * 500)
  var b = (Math.random() * 500)
  var c = (Math.random() * 500)
  var d = (Math.random() * 500)
  var e = (Math.random() * 500)
  var r = parseInt(a + b + c + d + e)
  var rate = parseFloat((((1 * a) + (2 * b) + (3 * c) + (4 * d) + (5 * e)) / r).toFixed(1))
  return [{ rate, review: r }]
}

export const BnC = () => {

  const category = []
  ProductData().map((v) => category.push(v.category))
  var c = [...new Set(category)]
  console.log(c)
  ProductData().filter((value, index, array) => value.brand.wh)
}
export const SendEmail = async (orders) => {
  //return await fetch("https://picolocal.herokuapp.com/email/?order=" + encodeURIComponent(order))
  var order = await (orders)
  console.log(order)
  var html = ''

  order.items.map(
    (value, index, array) =>
    (html += `<div style="display:block; padding: .0rem .3rem; margin:.0rem .3rem; align-items: center; flex-direction: column; gap: 1rem;">
  <img style="display:block; margin-left: auto; margin-right: auto; height:8rem; width: 8rem" src=${value.img}>
    <p style="font-size: .8rem; ">${value.name}</p>
            <p style="font-size: .65rem; font-weight: bold">${value.price} &nbsp; <span style="font-size: .65rem">Quantity: ${value.qty}</span></p>
    </div><br>`),
  )

  var ehtml = `
  <div
  style="
    border-radius: 0.6rem;
    max-width: 380px;
    background: #f6f6f6;
    font-family: Poppins;
  "
>
  <style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@100;200;300;400;500;600;700;800;900&display=swap');
  </style>
  <div style="padding: 2rem 2rem;">
  <div style="align-items: center;">
<img
style="width: 8rem; display: block;  margin-left: auto; margin-right: auto;"
  src="https://buycheap-22fcd.web.app/avart.ico"
  alt="PICOLOCAL"
/>
<p>YOUR ORDER PLACED WITH NUMBER: ${order.orderno}</p>
<h1 style="text-align: center; margin-bottom: 10px;">Picolocal</h1>
</div><br>
    <strong><p id="username">Dear ${order.username}</p></strong>
    <p style="font-size: 0.8rem;">
      Your order #${order.orderno} has been places successfully.
    </p>
    <p style="font-size: 0.6rem;">Fullname: ${order.username}</p>
    <p style="font-size: 0.6rem;">Phone Number: ${order.phone}</p>
    <p style="font-size: 0.6rem;">Email: ${order.email}</p>
    <p style="font-size: 0.6rem;">Address: ${order.address}</p>
    <p style="font-size: 0.6rem;">Country: ${order.country}</p>
    <p style="font-size: 0.6rem;">Date: ${order.date}</p>
    <p style="font-size: 0.6rem;">City: ${order.city}</p>
  </div>

  <div
    id="list"
    style="background: #fdfdfd; border-radius: 0.3rem; padding: 1rem; margin:2rem;"
  >${html}</div>
  <div id="summary" style="margin-top: 2rem;"></div>
  <!-- logics here -->

    <div style="background: #bbbbbb; padding: 2rem 2rem; margin: 2rem;">
     <p style="font-size: 1.3rem; font-weight: bold"> Shipping Address</p>
     <p style="font-size: .6rem;"> FullName: <span style="font-size: 0.8rem; font-weight: bold">${order.shipping.name}</span></p>
     <p style="font-size: .6rem;"> Phone No.: <span style="font-size: 0.8rem; font-weight: bold">${order.shipping.phone}</span></p>
     <p style="font-size: .6rem;"> Shipping Address: <span style="font-size: 0.8rem; font-weight: bold">${order.shipping.address}</span></p>
     <p style="font-size: .6rem;"> Shipping Agent: <span style="font-size: 0.8rem; font-weight: bold">${order.shipping.agent}</span></p>
     <p style="font-size: .6rem;"> Contact: <span style="font-size: 0.8rem; font-weight: bold">${order.shipping.phone}</span></p>
  
    <p style="font-size: .8rem;">Total Price: <span style="font-size: 1.5rem; font-weight: bold">$${order.amount}</span></p>
    </div>
 
</div>`

  var receipt = {
    "personalizations": [
      {
        "to": [
          {
            "email": `${order.email}`
          }
        ],
        "subject": `ORDER ${order.orderno} is successfully placed.`
      }
    ],
    "from": {
      "email": "PICOLOCAL <support@picolocal.com>"
    },
    "content": [
      {
        "type": "text/html",
        "value": `${ehtml}`
      }
    ]
  }

  console.log(receipt)

  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-RapidAPI-Key': '1080ba1908msh027057859d83ed0p13ac36jsn0ac1373f9e2d',
      'X-RapidAPI-Host': 'rapidprod-sendgrid-v1.p.rapidapi.com'
    },
    body: JSON.stringify(receipt)
  }
  return await fetch('https://rapidprod-sendgrid-v1.p.rapidapi.com/mail/send', options)

    .catch(err => console.log(err))
}
//"{\"body\":\"<p>classname</p>\" ,\n    \"isHtml\": true, \"plaintextAlternativeBody\": \"Piclolocal Store\",\n    \"subject\": \"Order 7S0CCWGEOHYB places successful\",\n    \"from\": { \"address\": \"lowhgstore@gmail.com\", \"name\": \"PICOLOCAL\" },\n    \"toAddresses\": [{\n      \"name\": \"Perfect Wine\",\n      \"address\": \"otengs16@gmail.com\"\n    },\n    \"Perfect Wine\",\n    \"Perfect Wine <otengs16@gmail.com>\"\n  ],\n    \"smtp\": {\n      \"host\": \"smtp.elasticemail.com\",\n      \"port\": 2525,\n      \"requiresAuthentication\": true,\n      \"userName\": \"support@picolocal.com\",\n      \"password\": \"973689B669CCABB92DA02AA41E4B70A8FB3E\",\n      \"useSsl\": false\n    }\n}"

export const Counter = () => {
  // Get today's date and time
  var now = new Date().getTime();
  
  var distance = Date.parse("28 Nov 2022 06:00:00 GMT") - now;
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);
  var x = setInterval(() => {
    // If the count down is over, write some text 
    if (distance < 0) {
      clearInterval(x);
      return [true];
    }
    return [days, hours, minutes, seconds]
  }, 1000);
  return [days, hours, minutes, seconds]
}