import { useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom"
import { GetADoc, Signinuser } from "../../config/db";
import { AlertDialog, Progress } from "../alert/alert";
import logo from '../../images/avart.png'




export const Signin = () => {

    const [valid, setValid] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [info, setInfo] = useState([])

    const emailRef = useRef()
    const passRef = useRef()

    const navigate = useNavigate();

    return (
        <section className="gradient-form bg-gray-200 py-10">
            <div className="container py-12 px-6 h-full mx-auto">
                <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">

                    <div className="block bg-white shadow-lg rounded-lg">
                        <div className="lg:flex lg:flex-wrap g-0">
                            <div className="px-4 md:px-0">
                                <div className="md:p-12 md:mx-6">
                                    <div className="text-center">
                                        <img
                                            className="mx-auto w-48"
                                            src={logo}
                                            alt="logo"
                                        />
                                        <h4 className="text-xl font-semibold mt-1 mb-12 pb-1">Welcome back to our store.</h4>
                                    </div>
                                    <div>
                                        <p className="mb-4">Please login to your account</p>
                                        <div className="mb-4">
                                            <input
                                                type="email"
                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                id="exampleFormControlInput1"
                                                placeholder="Email address"
                                                onChange={(e) => setEmail(e.target.value)}
                                                value={email}
                                                ref={emailRef}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                type="password"
                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                id="exampleFormControlInput1"
                                                placeholder="Password"
                                                onChange={(e) => setPassword(e.target.value)}
                                                value={password}
                                                ref={passRef}
                                            />
                                        </div>
                                        <div className="text-center pt-1 mb-12 pb-1">
                                            <button
                                                className="inline-block px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3"
                                                type="button"
                                                data-mdb-ripple="true"
                                                data-mdb-ripple-color="light"
                                                style={{
                                                    background: "linear-gradient(to right, #333333, #343434, #373737, #000000)"
                                                }}
                                                onClick={() => {

                                                    if (!email) {
                                                        setValid(false)
                                                        emailRef.current.focus()
                                                        AlertDialog({
                                                            title: "Error",
                                                            msg: "Please enter your email address",
                                                            conf: false,
                                                            nbtn: "Close"
                                                        })
                                                        return
                                                    }

                                                    if (!(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/).test(email)) {
                                                        setValid(false)
                                                        emailRef.current.focus()
                                                        AlertDialog({
                                                            title: "Error",
                                                            msg: "Please format your email address well",
                                                            conf: false,
                                                            nbtn: "Close"
                                                        })
                                                        return
                                                    }

                                                    if (!password) {
                                                        setValid(false)
                                                        passRef.current.focus()
                                                        AlertDialog({
                                                            title: "Error",
                                                            msg: "Please enter your password",
                                                            conf: false,
                                                            nbtn: "Close"
                                                        })
                                                        return
                                                    }
                                                    AlertDialog({
                                                        title: "Signing to account",
                                                        msg: "Are you sure you want to proceed with the log in?",
                                                        conf: true,
                                                        nbtn: "Cancel",
                                                        ybtn: "Sign in",
                                                        action: () => {

                                                            Progress("Please wait while we create your account.")
                                                            Signinuser({ email, password }).then((v) => {

                                                                AlertDialog({
                                                                    title: "Signin completed",
                                                                    msg: "You have successfully signed in your account.",
                                                                    conf: false,
                                                                    nbtn: "Close",
                                                                })
                                                                navigate('/', { replace: true })


                                                            }).catch((v => {
                                                                AlertDialog({
                                                                    title: "Signup Error",
                                                                    msg: v.message.replace("Firebase: ", ""),
                                                                    conf: false,
                                                                    nbtn: "Close"
                                                                })
                                                                //setCompleted(false)
                                                            }

                                                            ))
                                                        }
                                                    })
                                                }}
                                            >
                                                Log in
                                            </button>
                                            <a className="text-gray-500" href="#!">Forgot password?</a>
                                        </div>
                                        <div className="flex items-center justify-between pb-6">
                                            <p className="mb-0 mr-2">Don't have an account?</p>
                                            <NavLink
                                                to='/signupforaaccount'

                                                className="inline-block px-6 py-2 border-2 border-gray-600 text-gray-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                                                data-mdb-ripple="true"
                                                data-mdb-ripple-color="light"
                                            >
                                                Sign up
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div >
        </section >
    )
}