

import React from 'react'
import { useLocation } from 'react-router-dom'
import { Footer, Navbar, Divider, Bestseller, } from '../components/home/component'
import { Search2Query } from '../components/search/Search2'


const Search2Page = () => {
    const location = useLocation()
    document.title = location.pathname.replace('/', '').FirstUppercase();
    
    return (<div className='flex flex-col w-full'>
        <div className="bg-black w-full sticky top-0 z-50">
            <Navbar />
        </div>
            <Search2Query />
            <Bestseller />
            <Divider />
            <Footer />
    </div>
    )
}

export default Search2Page