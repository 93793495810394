

import React from 'react'
import { Account } from '../components/account/accountcomp'
import { Divider, Footer, Navbar } from '../components/home/component'
import CheckoutComp from '../components/checkout/checkout_comp';
import { useLocation } from 'react-router-dom';

const CheckoutPage = () => {
   const location = useLocation()
   document.title = location.pathname.replace('/','').FirstUppercase();
    return (<div className='flex flex-col w-full'>
        <div className="bg-black w-full sticky top-0 z-50">
            <Navbar />
        </div>
            <CheckoutComp />
            <Footer />
    </div>
    )
}

export default CheckoutPage