

import React from 'react'
import { useLocation } from 'react-router-dom'
import { Divider, Footer, Navbar } from '../components/home/component'
import { Payments } from '../components/payment/Payments'

const PaymentPage = () => {
    const location = useLocation()
    document.title = location.pathname.replace('/', '').FirstUppercase();
    return (<div className='flex flex-col w-full'>
        <div className="bg-black w-full sticky top-0 z-50">
            <Navbar />
        </div>
            <Payments />
            <Divider />
            <Footer />
    </div>
    )
}

export default PaymentPage