import React, { useState, useRef, useLayoutEffect } from 'react';
import { Formatter } from '../../config/function';

export const Tab1 = ({ list }) => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')

    const [oldpass, setOldpass] = useState('')
    const [newpass, setNewPass] = useState('')
    const [confpass, setConfpass] = useState('')
    const [avatar, setAvatar] = useState('')


    const nameref = useRef();
    const emailref = useRef();
    const phoneref = useRef();
    const addressref = useRef();
    const oldpassref = useRef();
    const newpassref = useRef();
    const confpassref = useRef();

    useLayoutEffect(() => {
        setAvatar(list.avatar)
        setName(list.name)
        setEmail(list.email)
        setAddress(list.address)
        setPhone(Formatter(`+${list.code} ${list.phone}`))
        setOldpass(list.password)
    }, [list.address, list.avatar, list.code, list.email, list.name, list.password, list.phone])
    return (
        <div>
            <h1 className="font-bold text-3xl"> Account information </h1>
            <div className="w-full mt-5 flex flex-col md:flex-row">
                <div className="w-48 mb-5">
                    <img src={avatar} alt="online shop" className="object-contain rounded-full bg-gray-200 p-2 h-32 w-32" />
                </div>
                <div className="w-full">
                    <p className="text-lg mb-2">Full Name</p>
                    <input className="px-4 py-2 mb-4 border-1 focus:shadow-md focus:shadow-bg-300 focus:border-2 border-gray-400 rounded-2xl outline-none w-full" type="text" name="fullname" id="name" value={name} ref={nameref} onChange={(e) => setName(e.target.value)} />

                    <p className="text-lg mb-2">Email address</p>
                    <input className="px-4 py-2 mb-4 border-1 focus:shadow-md focus:shadow-bg-300 focus:border-2 border-gray-400 rounded-2xl outline-none w-full" type="email" name="email" id="email" value={email} ref={emailref} onChange={(e) => setEmail(e.target.value)} />

                    <p className="text-lg mb-2">Phone number</p>
                    <input className="px-4 py-2 mb-4 border-1 focus:shadow-md focus:shadow-bg-300 focus:border-2 border-gray-400 rounded-2xl outline-none w-full" type="text" name="phone" id="phone" value={phone} ref={phoneref} onChange={(e) => setPhone(e.target.value)} />

                    <p className="text-lg mb-2">Location</p>
                    <input className="px-4 py-2 mb-4 border-1 focus:shadow-md focus:shadow-bg-300 focus:border-2 border-gray-400 rounded-2xl outline-none w-full" type="text" name="address" id="address" value={address} ref={addressref} onChange={(e) => setAddress(e.target.value)} />

                    <button className="bg-black text-white text-lg px-6 py-2 rounded-full mt-4">Update list</button>

                    <h1 className="font-bold text-3xl mt-10"> Change Password</h1>
                    <div className="w-full mt-10">
                        <p className="text-lg mb-2">Old password</p>
                        <input className="px-4 py-2 mb-4 border-1 focus:shadow-md focus:shadow-bg-300 focus:border-2 border-gray-400 rounded-2xl outline-none w-full" type="password" name="oldpass" id="oldpass" value={oldpass} ref={oldpassref} onChange={(e) => setOldpass(e.target.value)} />

                        <p className="text-lg mb-2">New password</p>
                        <input className="px-4 py-2 mb-4 border-1 focus:shadow-md focus:shadow-bg-300 focus:border-2 border-gray-400 rounded-2xl outline-none w-full" type="password" name="newpass" id="newpass" value={newpass} ref={newpassref} onChange={(e) => setNewPass(e.target.value)} />

                        <p className="text-lg mb-2">Confirm new password</p>
                        <input className="px-4 py-2 mb-4 border-1 focus:shadow-md focus:shadow-bg-300 focus:border-2 border-gray-400 rounded-2xl outline-none w-full" type="password" name="confpass" id="confpass" value={confpass} ref={confpassref} onChange={(e) => setConfpass(e.target.value)} />

                        <button className="bg-black text-white text-lg px-6 py-2 rounded-full mt-4">Update password</button>
                    </div>
                </div>
            </div>
        </div>
    )
}