import { useState } from 'react'
import { UID } from '../../config/configdata'
import { SetADoc } from '../../config/db'
import { Catitem, Item, AddToCartAction, PriceDistribution, Mformatter, Discount, PriceD, Quotient } from '../../config/function'
import likeoff from '../../images/likeoff.svg'
import rate from '../../images/rate.svg'
import { AlertDialog, Progress, QuickView } from '../alert/alert'


export const Smartwatches = () => {
    const [object, setObject] = useState(...Item("qybe8sKWkxV2lbXQ3G25f1E82RnmM2"));
    var account = JSON.parse(localStorage.getItem('account'))
    const [qty, setQty] = useState(1);
    return (
        <div className="w-full flex flex-col mx-auto px-4">
            <div className="flex flex-col mt-20 p-4">
                <div className="flex w-full lg:w-4/6 mx-auto">
                    <h1 className='text-xl lg:text-2xl font-bold'>Phones and Tablets</h1>
                    <div className="flex gap-3">
                        <div className="rounded-full p-2"> </div>
                        <div className="rounded-full p-2"> </div>
                    </div>
                </div>
                <div className="w-full flex-col md:grid md:grid-cols-2 lg:grid lg:grid-cols-4 items-center gap-4 mt-10 mx-auto lg:w-4/6">
                    {Catitem("watches").map((value, index, array) => (
                        <div className="flex flex-col p-4 gap-2 rounded-lg w-full bg-slate-50" key={index}>
                            <div className="flex justify-between w-full">
                                <p className="p-2 rounded-lg bg-white text-sm">{value.brand}</p>
                                <img className='h-10 p-2 rounded-full bg-white' src={likeoff} alt="heart" />
                            </div>
                            <div className="flex p-2 justify-center relative">
                                <img className="object-contain h-48 w-44" src={value.img} alt={value.displayname} />
                                {value.discount > 0 ? <div className="flex flex-col justify-center items-center rounded-full bg-black px-4 py-2 absolute right-2 -top-5">
                                     {parseInt(Discount((value.price / Quotient), PriceD(value)).replace('%', '')) > 0 && ( <h1 className='text-white text-xs font-bold'>was {Mformatter(value.price / Quotient)}</h1>)}
                                </div> : null}
                                {parseInt(Discount((value.price / Quotient), PriceD(value)).replace('%', '')) > 0 && (<p className="font-bold text-black rounded-full bg-gray-200 w-max h-max p-2 mt-4">-{Discount((value.price / Quotient), PriceD(value))}</p>)}
                            </div>
                            <div className="flex justify-center gap-4 my-2">
                                <button className="rounded-2xl py-2 px-4 text-xs hover:shadow-md cursor:hover hover:translate-y-1 delay-100 bg-black text-white"
                                    onClick={() => {
                                        AddToCartAction(value, account, qty, setQty)
                                    }}
                                >Add to cart</button>
                                <button className="rounded-2xl py-2 px-4 text-xs hover:shadow-md cursor:hover hover:translate-y-1 delay-100 border-1 border-black"
                                    data-bs-toggle="modal" data-bs-target="#quickview"
                                    onClick={() => setObject(value)}
                                >Quick view</button>
                                <QuickView object={object} />

                            </div>
                            {/* <div className="flex gap-2">
                                <div className="rounded-full h-4 w-4 p-1 bg-blue-300" ></div>
                                <div className="rounded-full h-4 w-4 p-1 bg-red-300" ></div>
                                <div className="rounded-full h-4 w-4 p-1 bg-green-300" ></div>
                                <div className="rounded-full h-4 w-4 p-1 bg-orange-300" ></div>
                            </div> */}
                            <div className="bg-white rounded-lg p-2">
                                <div className="flex flex-col gap-2 mt-4">
                                    <h1 className="text-md font-bold truncate ">{value.displayname}</h1>
                                    <h1 className="text-sm font-normal truncate">{value.short}</h1>
                                </div>
                                <div className="flex justify-between mt-5">
                                    <button className="border-2 border-green-600 rounded-lg bg-white px-2 text-green text-lg font-bold text-green-600 hover:bg-green-200">{PriceDistribution(value)}</button>
                                    <div className="flex gap-2 items-center">
                                        <img src={rate} alt="rate" className="h-4" />
                                        <p className="text-sm">{value.rate} ({value.review} reviews)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
/* 
amazon fire, tablet,amazon fire tablet
,fire hd 8
,amazon fire hd 10
,decathlon online shopping
,clothing websites
,shein shop
,online clothes shopping
,amazon kindle reader
,clothes online
,tory burch outlet online
,fire 7 tablet
,clothing boutiques near me
,online dress shopping
,kindle tablet
,amazon kindle fire
,amazon smartphone
,cheap clothing websites
,online jewelry stores
,amazon fire 7 tablet
,amazon earrings
,nike shop online
,buy shoes
,sneakers online
,kindle fire 10
,birkenstock amazon
,amazon fire tablet 10
,shoes online shopping
,alexa home
,clothing sites
,asos online shop
,amazon jeans
,nike online shopping
,women dresses online
,amazon nike
,shein fashion shopping online
,amazon gifts for her
,amazon sneakers
,amazon bluetooth headphones
,kindle ereader
,echo dot 2nd generation
,converse amazon
,amazon boots
,amazon vans
,olaplex amazon
,amazon watches for men
,22k gold jewellery online shopping
,samsung a52 amazon
,trendy clothing websites
,amazon necklace
,blender amazon
,dog toys amazon
,amazon laptop price
,amazon laptop deals
,amazon kindle price
,amazon summer dresses
,amazon wireless headphones
,online dress stores
,dior tote bag price
,best online dress stores
,ebay vans
,silver jewlery online
,cheap vintage clothing
,chanel online store
,cheap dresses online
,shine online shopping
,zara online store
,ross dress for less online shopping
,men clothing websites
,wholesale online shopping
,asos online shopping
,online electronics store
,victoria secret online shopping
,ebay led lights
,shein clothes for women
,ladies dresses online
,gucci online store
,new look online shopping
,best online shopping sites for clothes
,ebay tv
,fashion shop online
,boutique online shopping
,shoes for women online
,ebay windows 10
,shoe sale online
,turkish clothing online store
,buy shirts online
,mango clothing store
,jewellery website
,shein clothing store near me
,shein online store
,top 10 online clothing stores
,ebay iphone 11 pro max
,fashion online shopping
,ebay iphone 12 pro max
,monsoon sale dresses online
,electrical project
,macbook ebay
,zara dresses online
,fashion nova online shopping
,ralph lauren online shop
,fire hd 8
,amazon fire hd 10
,decathlon online samazon fire tablet
,fire hd 8
,amazon fire hd 10
,decathlon online shopping
,clothing websites
,shein shop
,online clothes shopping
,amazon kindle reader
,clothes online
,tory burch outlet online
,fire 7 tablet
,clothing boutiques near me
,online dress shopping
,kindle tablet
,amazon kindle fire
,amazon smartphone
,cheap clothing websites
,online jewelry stores
,amazon fire 7 tablet
,amazon earrings
,nike shop online
,buy shoes
,sneakers online
,kindle fire 10
,birkenstock amazon
,amazon fire tablet 10
,shoes online shopping
,alexa home
,clothing sites
,asos online shop
,amazon jeans
,nike online shopping
,women dresses online
,amazon nike
,shein fashion shopping online
,amazon gifts for her
,amazon sneakers
,amazon bluetooth headphones
,kindle ereader
,echo dot 2nd generation
,converse amazon
,amazon boots
,amazon vans
,olaplex amazon
,amazon watches for men
,22k gold jewellery online shopping
,samsung a52 amazon
,trendy clothing websites
,amazon necklace
,blender amazon
,dog toys amazon
,amazon laptop price
,amazon laptop deals
,amazon kindle price
,amazon summer dresses
,amazon wireless headphones
,online dress stores
,dior tote bag price
,best online dress stores
,ebay vans
,silver jewlery online
,cheap vintage clothing
,chanel online store
,cheap dresses online
,shine online shopping
,zara online store
,ross dress for less online shopping
,men clothing websites
,wholesale online shopping
,asos online shopping
,online electronics store
,victoria secret online shopping
,ebay led lights
,shein clothes for women
,ladies dresses online
,gucci online store
,new look online shopping
,best online shopping sites for clothes
,ebay tv
,fashion shop online
,boutique online shopping
,shoes for women online
,ebay windows 10
,shoe sale online
,turkish clothing online store
,buy shirts online
,mango clothing store
,jewellery website
,shein clothing store near me
,shein online store
,top 10 online clothing stores
,ebay iphone 11 pro max
,fashion online shopping
,ebay iphone 12 pro max
,monsoon sale dresses online
,electrical project
,macbook ebay
,zara dresses online
,fashion nova online shopping
,ralph lauren online shop
,clothing websites
,shein shop
,online clothes shopping
,amazon kindle reader
,clothes online
,tory burch outlet online
,fire 7 tablet
,clothing boutiques near me
,online dress shopping
,kindle tablet
,amazon kindle fire
,amazon smartphone
,cheap clothing websites
,online jewelry stores
,amazon fire 7 tablet
,amazon earrings
,nike shop online
,buy shoes
,sneakers online
,kindle fire 10
,birkenstock amazon
,amazon fire tablet 10
,shoes online shopping
,alexa home
,clothing sites
,asos online shop
,amazon jeans
,nike online shopping
,women dresses online
,amazon nike
,shein fashion shopping online
,amazon gifts for her
,amazon sneakers
,amazon bluetooth headphones
,kindle ereader
,echo dot 2nd generation
,converse amazon
,amazon boots
,amazon vans
,olaplex amazon
,amazon watches for men
,22k gold jewellery online shopping
,samsung a52 amazon
,trendy clothing websites
,amazon necklace
,blender amazon
,dog toys amazon
,amazon laptop price
,amazon laptop deals
,amazon kindle price
,amazon summer dresses
,amazon wireless headphones
,online dress stores
,dior tote bag price
,best online dress stores
,ebay vans
,silver jewlery online
,cheap vintage clothing
,chanel online store
,cheap dresses online
,shine online shopping
,zara online store
,ross dress for less online shopping
,men clothing websites
,wholesale online shopping
,asos online shopping
,online electronics store
,victoria secret online shopping
,ebay led lights
,shein clothes for women
,ladies dresses online
,gucci online store
,new look online shopping
,best online shopping sites for clothes
,ebay tv
,fashion shop online
,boutique online shopping
,shoes for women online
,ebay windows 10
,shoe sale online
,turkish clothing online store
,buy shirts online
,mango clothing store
,jewellery website
,shein clothing store near me
,shein online store
,top 10 online clothing stores
,ebay iphone 11 pro max
,fashion online shopping
,ebay iphone 12 pro max
,monsoon sale dresses online
,electrical project
,macbook ebay
,zara dresses online
,fashion nova online shopping
,ralph lauren online shop */