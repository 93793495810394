import React from 'react'
import { ArrivalData } from '../../data/data_items'

export const Tab2 = ({ list }) => {
    return (
        <div>
            <h1 className="font-bold text-3xl"> Wishlists</h1>
            <div className="w-full mt-5 flex flex-col md:flex-row">
                <div className="w-full flex-col md:grid md:grid-cols-2 lg:grid lg:grid-cols-3 items-center gap-4 mt-10 mx-auto">

                            <h1 className="text-xl">No Wishlist item(s)</h1>
                   {/*  {ArrivalData().map((value, index, array) => (
                        <div className="flex flex-col p-4 gap-2 rounded-lg w-full bg-slate-100" key={index}>
                            <div className="flex justify-between w-full">
                                <p className="p-2 rounded-lg bg-white text-sm">{value.notice}</p>
                                <img className='h-10 p-2 rounded-full bg-white' src={list.avatar} alt="heart" />
                            </div>
                            <div className="flex p-2 justify-center relative">
                                <img className="object-contain h-48 w-44" src={value.img} alt={value.title} />

                            </div>
                            <div className="flex justify-center gap-4 my-2">
                                <button className="rounded-2xl py-2 px-4 text-xs hover:shadow-md cursor:hover hover:translate-y-1 delay-100 bg-black text-white">Add to card</button>
                                <button className="rounded-2xl py-2 px-4 text-xs hover:shadow-md cursor:hover hover:translate-y-1 delay-100 border-1 border-black">Quick view</button>
                            </div>
                            <div className="flex gap-2">
                                <div className="rounded-full h-4 w-4 p-1 bg-blue-300" ></div>
                                <div className="rounded-full h-4 w-4 p-1 bg-red-300" ></div>
                                <div className="rounded-full h-4 w-4 p-1 bg-green-300" ></div>
                                <div className="rounded-full h-4 w-4 p-1 bg-orange-300" ></div>
                            </div>
                            <div className="bg-white rounded-lg p-2">
                                <div className="flex flex-col gap-2 mt-4">
                                    <h1 className="text-md font-bold">{value.title}</h1>
                                    <h1 className="text-sm font-normal truncate">{value.desc}</h1>
                                </div>
                                <div className="flex justify-between mt-5">
                                    <button className="border-2 border-green-600 rounded-lg bg-white px-2 text-green text-lg font-bold text-green-600 hover:bg-green-200">${(value.price - (value.price * (value.discount / 100))).toFixed(2)}</button>
                                    <div className="flex gap-2 items-center">
                                        <img src={list.avatar} alt="rate" className="h-4" />
                                        <p className="text-sm">{value.rate} ({value.review} reviews)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))} */}
                </div>
            </div>
        </div>
    )
}
