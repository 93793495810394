import likeoff from '../../images/likeon.svg'
import { useLayoutEffect, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FullCart } from '../../data/data_items'
import { GetADoc, GetADocWhere, SetADoc } from '../../config/db'
import { AlertDialog, Progress, QuickView, Edit } from '../alert/alert'
import { Mformatter, Item, PriceD } from '../../config/function';
import '../../index.css'
import { where } from 'firebase/firestore'
import { PaymentDialog } from '../alert/alert'

import emptycart from '../../images/emptycart.png'

export const Cart = () => {
    const [cartitems, setCartitems] = useState([])
    const navigate = useNavigate();
    const [totalbal, setTotalbal] = useState(0)
    const [cartno, setCartno] = useState(0);
    const [qty, setQty] = useState(1);
    var prices = 0;

    var account = JSON.parse(localStorage.getItem('account'))

    try {


        if (account) {
            GetADocWhere('cart', where('email', '==', account.email))
                .then((value) => {
                    setCartno(value.length)
                    setCartitems(value)
                    value.forEach((value, index, array) => {
                        prices += parseFloat((value.price) * value.qty)
                    })
                    setTotalbal(prices)
                }).catch((err) => { console.log(err) })
        }
    } catch (error) {

    }




    return (
        <div className="w-full flex flex-col lg:w-4/6 mx-auto mt-10 px-4">
            <h1 className="font-bold text-4xl">Shopping Cart</h1>


            <div className="flex flex-col lg:flex-row justify-between gap-16 mt-20 border-t-1 border-t-gray-200">
                {FullCart(cartitems).length > 0 ? <div className="w-full lg:w-3/5">

                    {FullCart(cartitems).map((value, index, array) => {

                        return (
                            <div className="flex w-full  gap-4 mt-10 h-max" key={index}>
                                <img src={value.img} alt={value.brand} className="object-contain h-32 w-28 bg-gray-200 p-2 rounded-lg" />
                                <div className="flex flex-col w-full justify-between">
                                    <div className="flex flex-col w-full gap-1">
                                        <div className="flex w-full justify-between gap-4">
                                            <p className="font-bold text-md line-clamp-2">{value.displayname}</p>
                                            {/* <div className="flex gap-4 justify-center items-center h-max">
                                                <p className="p-2 rounded-full hover:bg-gray-50 h-8 w-8 border-1 border-gray-400 cursor-pointer text-center text-md font-medium flex items-center justify-center" onClick={() => qty > 1 && setQty(--qty)}>-</p>
                                                <p className=" text-md font-medium flex justify-center">{cartitems[index].qty}</p>
                                                <p className="p-2 rounded-full hover:bg-gray-50 h-8 w-8 border-1 border-gray-400 cursor-pointer text-center text-md font-medium flex items-center justify-center" onClick={() => qty < 10 && setQty(++qty)}>+</p>
                                            </div> */}
                                            <div className="px-2 rounded-lg border-2 border-green-600 text-green-600 h-max">{Mformatter(PriceD(value) * cartitems[index].qty)}</div>
                                        </div>
                                        <div className="flex gap-4 items-center text-gray-400">
                                            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none"><path d="M7.01 18.0001L3 13.9901C1.66 12.6501 1.66 11.32 3 9.98004L9.68 3.30005L17.03 10.6501C17.4 11.0201 17.4 11.6201 17.03 11.9901L11.01 18.0101C9.69 19.3301 8.35 19.3301 7.01 18.0001Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M8.35 1.94995L9.69 3.28992" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M2.07 11.92L17.19 11.26" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M3 22H16" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M18.85 15C18.85 15 17 17.01 17 18.24C17 19.26 17.83 20.09 18.85 20.09C19.87 20.09 20.7 19.26 20.7 18.24C20.7 17.01 18.85 15 18.85 15Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                            <p className="text-md">Color Variety</p>
                                            <p className="text-md">|</p>
                                            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none"><path d="M21 9V3H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M3 15V21H9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M21 3L13.5 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10.5 13.5L3 21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                            <p className="font-bold">{cartitems[index].qty}</p>
                                        </div>
                                    </div>

                                    <div className="flex justify-between">
                                        <div className="border-1 border-gray-400 px-4 py-1 text-sm text-gray-400 flex gap-2 rounded-full items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-3.5 h-3.5"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg>
                                            <p className="">in stock</p>
                                        </div>
                                        <p className="text-blue-600 font-bold cursor-pointer"
                                            onClick={() => Edit(cartitems[index].id, cartitems[index].qty)}
                                        >Edit Item</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div> : (<div className="flex flex-col items-center justify-center px-6">
                    <img src={emptycart} alt="emptycart" className="" />
                    <p className="font-bold mx-6 mb-4">Your cart is Empty</p>
                </div>)}



                <div className="hidden lg:flex border-r-1 borderr-gray-200 mt-10"></div>
                <div className="flex w-full lg:w-2/5 lg:px-6 flex-col gap-4 mt-4 lg:mt-10">
                    <h1 className="font-bold text-lg">Order Summary</h1>
                    <div className="justify-between flex border-b-1 border-b-gray-200 py-2">
                        <p className="text-sm text-gray-400">Subtotal</p>
                        <p className="text-sm text-black font-bold">{Mformatter((totalbal))}</p>
                    </div>
                    <div className="justify-between flex border-b-1 border-b-gray-200 py-2">
                        <p className="text-sm text-gray-400">Shipping estimate</p>
                        <p className="text-sm text-black font-bold">{Mformatter((totalbal * 0.025))}</p>
                    </div>
                    <div className="justify-between flex border-b-1 border-b-gray-200 py-2">
                        <p className="text-sm text-gray-400">Tax estimate</p>
                        <p className="text-sm text-black font-bold">{Mformatter((totalbal * 0.002))}</p>
                    </div>
                    {/*  */}
                    <div className="justify-between items-center flex border-b-gray-200 py-2">
                        <p className="text-xl font-bold text-black">Order total</p>
                        <p className="text-2xl text-black font-bold">{Mformatter(((totalbal + (totalbal * 0.025) + (totalbal * 0.002))))}</p>
                    </div>

                    {FullCart(cartitems).length > 0 && <Link to="/checkoutpage"><button className="w-full text-center text-white bg-black hover:shadow-md text-lg rounded-full py-2 mt-10">Checkout order</button></Link>}
                </div>
            </div>
        </div>
    )
}