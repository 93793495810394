import { Link, NavLink, useNavigate } from "react-router-dom"

import { useState, useRef, useLayoutEffect } from "react"
import { AlertDialog, Progress } from "../alert/alert"
import { BillingAddress, SetADoc, Signupuser } from "../../config/db"
import moment from "moment/moment"
import { UID } from "../../config/configdata"
import logo from '../../images/avart.png'



export const Signup = () => {

    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [address, setAddress] = useState("")
    const [passone, setPassone] = useState("")
    const [passtwo, setPasstwo] = useState("")




    const nameref = useRef()
    const phoneref = useRef()
    const emailref = useRef()
    const addressref = useRef()
    const passoneref = useRef()
    const passtworef = useRef()
    const termsref = useRef()

    const [valid, setValid] = useState(false);
    const [geo, setGeo] = useState({});
    const [completed, setCompleted] = useState(false)
    const [terms, setTerms] = useState(false)



    useLayoutEffect(() => {
        BillingAddress()
            .then(v => v.json())
            .then(v => setGeo(v));
    }, [])

    const navigate = useNavigate();


    return (
        <section className="gradient-form bg-gray-200 py-10">
            <div className="container py-12 px-6 h-full mx-auto">
                <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">

                    <div className="block bg-white shadow-lg rounded-lg">
                        <div className="lg:flex lg:flex-wrap g-0">
                            <div className="px-4 md:px-0">
                                <div className="md:p-12 md:mx-6">
                                    {/* <div class="bg-red-100 rounded-lg py-5 px-6 mb-4 text-base text-red-700 mb-3" role="alert">
                                            A simple danger alert with <a href="#" class="font-bold text-red-800">an example link</a>. Give it a click if you like.
                                        </div> */}
                                    <div className="text-center">
                                        <img
                                            className="mx-auto w-48"
                                            src={logo}
                                            alt="logo"
                                        />
                                        <h4 className="text-xl font-semibold mt-1 mb-12 pb-1">Welcome, new customer</h4>
                                    </div>
                                    <div>
                                        <p className="mb-4">Please register for your new account</p>
                                        <div className="mb-4">
                                            <input
                                                type="text"
                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                id="exampleFormControlInput2"
                                                placeholder="Fullname (First name, Last name, etc)"
                                                ref={nameref}
                                                onChange={(e) => setName(e.target.value)} value={name}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                type="text"
                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                id="exampleFormControlInput3"
                                                placeholder="Phone number"
                                                ref={phoneref}
                                                onChange={(e) => setPhone(e.target.value.replace(/\D/g, ""))} value={phone}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                type="email"
                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                id="exampleFormControlInput4"
                                                placeholder="Email address"
                                                ref={emailref}
                                                onChange={(e) => setEmail(e.target.value)} value={email.toLowerCase()}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                type="text"
                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                id="exampleFormControlInput5"
                                                placeholder="Location/Address"
                                                ref={addressref}
                                                onChange={(e) => setAddress(e.target.value)} value={address}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                type="password"
                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                id="exampleFormControlInput6"
                                                placeholder="Choose password"
                                                ref={passoneref}
                                                onChange={(e) => setPassone(e.target.value)} value={passone}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                type="password"
                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                id="exampleFormControlInput1"
                                                placeholder="Confirm password"
                                                ref={passtworef}
                                                onChange={(e) => setPasstwo(e.target.value)} value={passtwo}
                                            />
                                        </div>
                                        <div className="my-6">

                                            <input className='form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-gray-900 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer' ref={termsref} value={terms} onChange={(e) => setTerms(e.target.checked)} type="checkbox" id={"terms"} />
                                            <p className="">Accept our <Link to='/licensetermsandconditionsagreement' className="text-red-500 font-bold" >Terms&amp;Conditions</Link></p>
                                        </div>
                                        <div className="text-center pt-1 mb-12 pb-1">

                                            <button
                                                className="inline-block px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3"
                                                data-mdb-ripple="true"
                                                data-mdb-ripple-color="light"
                                                style={{ background: "linear-gradient(to right, #333333, #343434, #373737, #000000)" }}
                                                onClick={() => {

                                                    if (!name && name.length < 5) {
                                                        setValid(false)
                                                        nameref.current.focus()
                                                        AlertDialog({
                                                            title: "Error",
                                                            msg: "Please enter your full name in the name input field",
                                                            conf: false,
                                                            nbtn: "Close"
                                                        })
                                                        return
                                                    }

                                                    if (/^(?:((([^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]'’,\-.\s])){1,}(['’,\-.]){0,1}){2,}(([^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]'’,\-. ]))*)$/.test(name)) {
                                                        setValid(false)
                                                        nameref.current.focus()
                                                        AlertDialog({
                                                            title: "Error",
                                                            msg: "Please enter your last name and first name or other name in addition",
                                                            conf: false,
                                                            nbtn: "Close"
                                                        })
                                                        return
                                                    }

                                                    if (!phone) {
                                                        setValid(false)
                                                        phoneref.current.focus()
                                                        AlertDialog({
                                                            title: "Error",
                                                            msg: "Please enter your phone number",
                                                            conf: false,
                                                            nbtn: "Close"
                                                        })
                                                        return
                                                    }

                                                    if (phone.length < 10) {
                                                        setValid(false)
                                                        phoneref.current.focus()
                                                        AlertDialog({
                                                            title: "Error",
                                                            msg: "Please make sure your phone number is accurate",
                                                            conf: false,
                                                            nbtn: "Close"
                                                        })
                                                        return
                                                    }

                                                    if (!email) {
                                                        setValid(false)
                                                        emailref.current.focus()
                                                        AlertDialog({
                                                            title: "Error",
                                                            msg: "Please enter your email address",
                                                            conf: false,
                                                            nbtn: "Close"
                                                        })
                                                        return
                                                    }

                                                    if (!(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/).test(email)) {
                                                        setValid(false)
                                                        emailref.current.focus()
                                                        AlertDialog({
                                                            title: "Error",
                                                            msg: "Please format your email address well",
                                                            conf: false,
                                                            nbtn: "Close"
                                                        })
                                                        return
                                                    }

                                                    if (!address) {
                                                        setValid(false)
                                                        addressref.current.focus()
                                                        AlertDialog({
                                                            title: "Error",
                                                            msg: "Please enter your home address",
                                                            conf: false,
                                                            nbtn: "Close"
                                                        })
                                                        return
                                                    }

                                                    if (!passone) {
                                                        setValid(false)
                                                        passoneref.current.focus()
                                                        AlertDialog({
                                                            title: "Error",
                                                            msg: "Please choose a secured password",
                                                            conf: false,
                                                            nbtn: "Close"
                                                        })
                                                        return
                                                    }

                                                    if (!passtwo) {
                                                        setValid(false)
                                                        passtworef.current.focus()
                                                        AlertDialog({
                                                            title: "Error",
                                                            msg: "Please confirm your password",
                                                            conf: false,
                                                            nbtn: "Close"
                                                        })
                                                        return
                                                    }


                                                    if (passone !== passtwo) {
                                                        setValid(false)
                                                        passtworef.current.focus()
                                                        AlertDialog({
                                                            title: "Error",
                                                            msg: "Please passwords do not match, try again",
                                                            conf: false,
                                                            nbtn: "Close"
                                                        })
                                                        return
                                                    }

                                                    if (passone.length < 6) {
                                                        setValid(false)
                                                        passoneref.current.focus()
                                                        AlertDialog({
                                                            title: "Error",
                                                            msg: "Please enter at least 8 characters",
                                                            conf: false,
                                                            nbtn: "Close"
                                                        })
                                                        return
                                                    }

                                                    if (passtwo.length < 6) {
                                                        setValid(false)
                                                        passtworef.current.focus()
                                                        AlertDialog({
                                                            title: "Error",
                                                            msg: "Please enter at least 8 characters",
                                                            conf: false,
                                                            nbtn: "Close"
                                                        })
                                                        return
                                                    }
                                                    if (!terms) {
                                                        setValid(false)
                                                        termsref.current.focus()
                                                        AlertDialog({
                                                            title: "Error",
                                                            msg: "Please review all our terms&conditions and accept them to continue.",
                                                            conf: false,
                                                            nbtn: "Close"
                                                        })
                                                        return
                                                    }


                                                    AlertDialog({
                                                        title: "Create user account",
                                                        msg: "By clicking on Sign up you Agree to All of our Terms&amp;Conditions.\nAre you sure the data provided are accurate and wish to proceed?",
                                                        conf: true,
                                                        nbtn: "Cancel",
                                                        ybtn: "Sign up",
                                                        action: () => {

                                                            setCompleted(true)

                                                            Progress("Please wait while we sign you into your account.")


                                                            Signupuser({ email, password: passtwo }).then((v) => {

                                                                const data = {
                                                                    uid: v.user.uid,
                                                                    name,
                                                                    phone,
                                                                    email,
                                                                    address,
                                                                    avatar: "https://static.thenounproject.com/png/3488541-200.png",
                                                                    password: passtwo,
                                                                    city: geo.location.city,
                                                                    continent: geo.location.continent.name,
                                                                    country: geo.location.country.name,
                                                                    iso: geo.location.country.code,
                                                                    code: geo.location.country.calling_code,
                                                                    capital: geo.location.country.capital,
                                                                    balance: 0.00,
                                                                    ip: geo.ip,
                                                                    created: moment().format('MMMM Do YYYY, h:mm:ss a'),
                                                                    flag: geo.location.country.flag.wikimedia

                                                                };
                                                                localStorage.setItem('account', JSON.stringify(data.toString()))
                                                                localStorage.setItem('cartlocal', JSON.stringify(""))

                                                                const uid = UID(15)
                                                                SetADoc("billing", uid, {
                                                                    city: geo.location.city,
                                                                    continent: geo.location.continent.name,
                                                                    country: geo.location.country.name,
                                                                    iso: geo.location.country.code,
                                                                    code: geo.location.country.calling_code,
                                                                    capital: geo.location.country.capital,
                                                                    uid: data.uid,
                                                                    name,
                                                                    phone,
                                                                    email,
                                                                    address,
                                                                })
                                                                SetADoc("shipping", uid, {
                                                                    city: geo.location.city,
                                                                    continent: geo.location.continent.name,
                                                                    country: geo.location.country.name,
                                                                    iso: geo.location.country.code,
                                                                    code: geo.location.country.calling_code,
                                                                    capital: geo.location.country.capital,
                                                                    uid: data.uid,
                                                                    name,
                                                                    phone,
                                                                    email,
                                                                    address,
                                                                })
                                                                SetADoc("accounts", data.email, data).then((v) => {




                                                                    setTimeout(() => {
                                                                        AlertDialog({
                                                                            title: "Signup Success",
                                                                            msg: "Your account has been successfully created for you.",
                                                                            conf: false,
                                                                            nbtn: "Close"
                                                                        })
                                                                        navigate('/', { replace: true })
                                                                    }, 5000)

                                                                }).catch((v => {
                                                                    AlertDialog({
                                                                        title: "Signup Error",
                                                                        msg: v.message.replace("Firebase: ", ""),
                                                                        conf: false,
                                                                        nbtn: "Close"
                                                                    })
                                                                    setCompleted(false)
                                                                }))
                                                            }).catch((v => {
                                                                AlertDialog({
                                                                    title: "Signup Error",
                                                                    msg: v.message.replace("Firebase: ", ""),
                                                                    conf: false,
                                                                    nbtn: "Close"
                                                                })
                                                                setCompleted(false)
                                                            }

                                                            ))
                                                        }
                                                    })

                                                }}>
                                                Sign up
                                            </button>

                                        </div>

                                        <div className="flex items-center justify-between pb-6">
                                            <p className="mb-0 mr-2">Already having an account?</p>
                                            <NavLink
                                                to='/signintoaccount'
                                                className="inline-block px-6 py-2 border-2 border-gray-600 text-gray-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                                                data-mdb-ripple="true"
                                                data-mdb-ripple-color="light"
                                            >
                                                Sign in
                                            </NavLink>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}