import React from 'react'
import { useLocation } from 'react-router-dom';
import { PhonesTab } from '../components/eproducts/phone_tab';
import {
    Divider,
    Navbar,
    Bestseller,
    Footer
} from '../components/home/component';

const PhonesTabPage = () => {
   const location = useLocation()
   document.title = location.pathname.replace('/','').FirstUppercase();
    return (
        <div className='flex flex-col w-full'>
            <div className="bg-black w-full sticky top-0 z-50">
                <Navbar />
            </div>
            <PhonesTab />
            <Bestseller />
            <Divider />
            <Footer />
        </div>
    )
}

export default PhonesTabPage