
import { auth, db } from './configdata'
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import { collection, getDoc, deleteDoc, query, doc, getDocs, setDoc, updateDoc, where, onSnapshot, orderBy } from "firebase/firestore";


export const Signupuser = (list) => {
    return createUserWithEmailAndPassword(auth, list.email, list.password)

}

export const Signinuser = (list) => {
    return signInWithEmailAndPassword(auth, list.email, list.password)

}

export const Signout = async () => {
    return await auth.signOut()

}

export const SetADoc = async (col, id, list) => {
    
    return await setDoc(doc(db, col, id), list)
}
export const Snaphot = async (col, id) => {
    return await onSnapshot(doc(db, col, id))
    /*  const snapshot = onSnapshot(query(collection(db, col), condition), (qs) => {
         qs.forEach((doc) => {
             arr.push(doc.data())
         })
     }) */
}

export const GetADoc = async (col, id) => {
    return await getDoc(doc(db, col, id))
}
export const GetADocWhere = async (col, condition) => {
    let arr = []
    const snapshot = await getDocs(query(collection(db, col), condition))
    snapshot.forEach((doc) => {
        arr.push(doc.data())
    })

    return arr;
}

export const GetDocs = async (col) => {
    return await getDocs(collection(db, col));
}

export const UpdateADoc = async (id, col, list) => {
    return await updateDoc(doc(db, col, id), list)
}

export const DeleteADoc = async (id, col) => {
    await deleteDoc(doc(db, col, id))
}

export const DeleteMutipleDocs = async (col, list) => {
    for (let index = 0; index < list.length; index++) {
        await deleteDoc(doc(db, col, list[index]))
    }
}

export const BillingAddress = async () => {
    return await fetch('https://api.ipregistry.co/?key=xy7cimox9c559g24')
}


