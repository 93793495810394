import React from 'react'
import { Signin } from '../components/auth/signincomponent';
import { Divider, Footer, Navbar } from '../components/home/component';


const SigninPage = () => {
    document.title = "Picolocal | Auth - Signin"
    return (
        <div className='flex flex-col w-full'>
            <div className='sticky top-0 z-50 flex flex-col w-full bg-black'>
                <Navbar />
            </div>
            <Signin />
            <Divider />
            <Footer />
        </div>
    )
}

export default SigninPage