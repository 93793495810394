import React from 'react'
import { useLocation } from 'react-router-dom';
import { Laptops } from '../components/eproducts/laptops';
import {

    Divider,
    Navbar,
    Bestseller,
    Footer
} from '../components/home/component';

const LaptopPage = () => {
   const location = useLocation()
   document.title = location.pathname.replace('/','').FirstUppercase();
    return (
        <div className='flex flex-col w-full'>
            <div className="bg-black w-full sticky top-0 z-50">
                <Navbar />
            </div>
            <Laptops />
            <Bestseller />
            <Divider />
            <Footer />
        </div>
    )
}

export default LaptopPage